import styled from "@emotion/styled";
import Button from "@mui/material/Button";
import Link from "../../../components/commons/Link";

const StyledButton = styled(Button)`
  width: 100%;
  max-width: 200px;
  height: 70px;
  text-align: center;
`;

const MenuOption = ({ to, children }) => {
  return (
    <StyledButton variant="outlined" component={Link} to={to}>
      {children}
    </StyledButton>
  );
};

export default MenuOption;
