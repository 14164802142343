import React, { useState } from "react";
import {
  Box,
  Toolbar,
  Container,
  IconButton,
  AppBar,
  Drawer,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Divider,
} from "@mui/material";
import Link from "../Link";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { items } from "./Items";
import { StyledLink, LogoText } from "./Styles";
import { useUserState, useUserUpdater } from "../../../providers/UserProvider";
import DanteIcon from "../icons/DanteIcon/DanteIcon";
import { COMMONS_ROUTES } from "../../../routes/commons/constants";
import { useNavigate } from "react-router-dom";
import { verifyRoles, ROLES } from "dante-alighieri-roles";
import MenuItem from "@mui/material/MenuItem";
import SESSION_STORAGE_KEYS from "../../../constants/sessionStorage";

function HeaderBar() {
  const [toggle, toggleDrawer] = useState(false);
  const { isLoggedIn, roles } = useUserState();
  const navigate = useNavigate();
  const { logout } = useUserUpdater();

  const handleLogout = () => {
    logout();
    navigate(COMMONS_ROUTES.LOGIN);
    sessionStorage.removeItem(SESSION_STORAGE_KEYS.TOKEN);
    toggleDrawer(false);
  };

  return (
    <AppBar position="static" style={{ display: { xs: "block", md: "none" } }}>
      <Container maxWidth="xl" style={{ display: { xs: "block", md: "none" } }}>
        <Toolbar
          disableGutters
          sx={{
            display: { xs: "flex", md: "none" },
            justifyContent: "space-between",
          }}
        >
          <StyledLink
            to={isLoggedIn ? COMMONS_ROUTES.MENU : COMMONS_ROUTES.LOGIN}
          >
            <DanteIcon />
            <LogoText variant="h6">
              DANTE
              <br />
              ROSARIO
            </LogoText>
          </StyledLink>
          {isLoggedIn && (
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={() => toggleDrawer(true)}
              sx={{
                ml: 2,
              }}
            >
              <MenuIcon />
            </IconButton>
          )}
          <Drawer
            anchor="right"
            variant="temporary"
            open={toggle}
            onClose={() => toggleDrawer(false)}
            onOpen={() => toggleDrawer(true)}
          >
            <Box
              sx={{
                p: 2,
                height: 1,
              }}
            >
              <IconButton sx={{ mb: 2 }} onClick={() => toggleDrawer(false)}>
                <CloseIcon />
              </IconButton>

              <Divider sx={{ mb: 2 }} />
              <Box>
                {items.map((item, index) => {
                  return verifyRoles(roles, item.roles) ? (
                    <ListItemButton
                      key={index}
                      sx={{
                        padding: "0px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        textAlign: "center",
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <FiberManualRecordIcon />
                      </ListItemIcon>
                      <ListItemText>
                        <Link to={item.path}>
                          <MenuItem onClick={() => toggleDrawer(false)}>
                            {item.name}
                          </MenuItem>
                        </Link>
                      </ListItemText>
                    </ListItemButton>
                  ) : null;
                })}
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  position: "absolute",
                  bottom: "0",
                  left: "50%",
                  transform: "translate(-50%, 0)",
                }}
              >
                {verifyRoles(roles, [ROLES.SUPER_ADMIN]) ? (
                  <Link to={COMMONS_ROUTES.NUEVO_USUARIO}>
                    <MenuItem onClick={() => toggleDrawer(false)}>
                      Crear usuario
                    </MenuItem>
                  </Link>
                ) : null}
                {verifyRoles(roles, [ROLES.SUPER_ADMIN]) ? (
                  <Link to={COMMONS_ROUTES.MODIFICAR_USUARIO}>
                    <MenuItem onClick={() => toggleDrawer(false)}>
                      Modificar usuario
                    </MenuItem>
                  </Link>
                ) : null}
                {!roles.includes(ROLES.SUPER_ADMIN) ? (
                  <Link to={COMMONS_ROUTES.MODIFICAR_CONTRASENA}>
                    <MenuItem onClick={() => toggleDrawer(false)}>
                      Modificar contraseña
                    </MenuItem>
                  </Link>
                ) : null}
                <MenuItem onClick={handleLogout}>Cerrar sesión</MenuItem>
              </Box>
            </Box>
          </Drawer>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default HeaderBar;
