import { NavLink } from "react-router-dom";
import Button from "@mui/material/Button";

const paperSX = {
  color: (theme) => theme.palette.common.white,
  position: "relative",
  borderRadius: "0px",
  borderBottom: "2px solid transparent",
  transition: "border-bottom 500ms ease",
  "&:hover": {
    borderBottom: "2px solid white",
  },
};

const SX = {
  borderBottom: "2px solid white",
};

const NavItem = ({ to, children }) => (
  <Button
    component={NavLink}
    to={to}
    sx={paperSX}
    style={({ isActive }) => (isActive ? SX : undefined)}
  >
    {children}
  </Button>
);

export default NavItem;
