import { useState } from "react";
import useAxios from "axios-hooks";
import styled from "@emotion/styled";
import { useNavigate } from "react-router-dom";
import {
  Paper,
  IconButton,
  OutlinedInput,
  InputLabel,
  InputAdornment,
  FormControl,
  TextField,
  Alert,
  Typography,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import LoadingButton from "@mui/lab/LoadingButton";
import TransferList from "../../../components/commons/TransferList/TransferList";
import {
  useSnackbarUpdater,
  SNACKBAR_SEVERITY,
} from "../../../providers/SnackbarProvider";
import { COMMONS_ROUTES } from "../../../routes/commons/constants";

const StyledPaper = styled(Paper)`
  max-width: 600px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  padding: 25px;
  gap: 30px;
  margin-top: 60px;
`;

const NuevoUsuario = () => {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [checked, setChecked] = useState([]);
  const setSnackbar = useSnackbarUpdater();
  const [, postRegister] = useAxios(
    {
      url: "/auth/register",
      method: "POST",
    },
    { manual: true },
  );

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setErrorMessage("");
    const formData = Object.fromEntries(new FormData(event.target).entries());
    formData.roles = checked.length ? checked : null;
    try {
      await postRegister({ data: formData });
      setSnackbar({
        open: true,
        message: "El usuario ha sido creado",
        severity: SNACKBAR_SEVERITY.SUCCESS,
      });
      navigate(COMMONS_ROUTES.MENU);
    } catch (error) {
      setErrorMessage(
        error?.response?.data?.message ||
          "Ha ocurrido un error, por favor intentalo mas tarde.",
      );
    }
  };

  return (
    <StyledPaper component="form" onSubmit={handleSubmit}>
      {errorMessage && (
        <Alert
          sx={{ marginBottom: (theme) => theme.spacing(2) }}
          severity="error"
        >
          {errorMessage}
        </Alert>
      )}
      <TextField
        label="Usuario"
        variant="outlined"
        type="text"
        name="username"
        inputProps={{
          autocomplete: "new-password",
        }}
        required
        value={username}
        onChange={(event) => setUsername(event.target.value)}
      />
      <FormControl variant="outlined" required>
        <InputLabel htmlFor="outlined-password">Contraseña</InputLabel>
        <OutlinedInput
          id="outlined-password"
          label="Contraseña"
          required
          type={showPassword ? "text" : "password"}
          inputProps={{
            autocomplete: "new-password",
          }}
          name="password"
          value={password}
          onChange={(event) => setPassword(event.target.value)}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge="end"
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          }
        />
      </FormControl>
      <FormControl>
        <Typography mb={2}>Seleccione roles:</Typography>
        <TransferList
          optionsList={[
            "SUPER_ADMIN",
            "PROVEEDORES_CONSULTAS",
            "PROVEEDORES_CONFIGURACIONES",
            "PROVEEDORES_REGISTRO_PAGOS",
            "EMPLEADOS_CONSULTAS",
            "EMPLEADOS_CONFIGURACIONES",
            "SUELDOS_CONSULTAS",
            "SUELDOS_CONFIGURACIONES",
            "BECAS_CONSULTAS",
            "BECAS_CONFIGURACIONES",
            "ESPACIOS_CONSULTAS",
            "ESPACIOS_CONFIGURACIONES",
            "UNIDAD_DANTE_CONSULTAS",
            "UNIDAD_DANTE_CONFIGURACIONES",
            "AUSENCIAS_CONSULTAS",
            "AUSENCIAS_CONFIGURACIONES",
            "PLANTA_FUNCIONAL_PRIMARIA_CONSULTAS",
            "PLANTA_FUNCIONAL_PRIMARIA_CONFIGURACIONES",
            "PLANTA_FUNCIONAL_SECUNDARIA_CONSULTAS",
            "PLANTA_FUNCIONAL_SECUNDARIA_CONFIGURACIONES",
            "DOCUMENTOS_CONSULTAS",
            "DOCUMENTOS_CONFIGURACIONES",
          ]}
          checked={checked}
          setChecked={setChecked}
        />
      </FormControl>
      <LoadingButton
        variant="contained"
        type="submit"
        disabled={!username || !password || !checked.length}
        //loading={loading}
      >
        CREAR USUARIO
      </LoadingButton>
    </StyledPaper>
  );
};

export default NuevoUsuario;
