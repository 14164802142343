export const DOCUMENTOS_BASE_ROUTE = "/documentos";

const CONSULTAS_BASE_ROUTE = `${DOCUMENTOS_BASE_ROUTE}/consultas`;
const CONFIGURACIONES_BASE_ROUTE = `${DOCUMENTOS_BASE_ROUTE}/configuraciones`;

export const DOCUMENTOS_ROUTES = {
  MENU: DOCUMENTOS_BASE_ROUTE,
  // consultas
  CONSULTAS_DOCUMENTOS: `${CONSULTAS_BASE_ROUTE}/documentos`,
  // configuraciones
  CONFIGURACIONES_CATEGORIAS: `${CONFIGURACIONES_BASE_ROUTE}/categorias`,
};
