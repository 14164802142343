import SvgIcon from "@mui/material/SvgIcon";

const DanteIcon = ({ props }) => {
  return (
    <SvgIcon viewBox="0 0 1024 992" fontSize="large" {...props}>
      <path
        fill="#FFF"
        opacity="1"
        stroke="#FFF"
        strokeWidth="15"
        d="
M803.878174,333.134186 
 C807.713989,339.871948 809.896912,346.862793 811.969604,353.875977 
 C812.940979,357.162872 811.663574,360.456268 811.448608,363.749939 
 C810.731506,374.739105 811.291931,385.712311 812.759399,396.629669 
 C813.359375,401.093262 815.789978,404.629883 818.524719,408.066528 
 C825.153381,416.396454 831.803528,424.715271 838.197449,433.224609 
 C846.709106,444.552185 849.226257,458.329590 853.293762,471.452087 
 C859.785278,492.394714 866.510010,513.272034 872.549255,534.345093 
 C875.727295,545.434265 875.507629,556.584534 869.633362,567.201843 
 C865.437195,574.786072 858.977112,579.340698 851.369507,582.706299 
 C844.354614,585.809631 836.991150,586.412292 829.397339,586.102722 
 C823.015259,585.842590 821.691467,587.753540 824.396790,593.509766 
 C826.745300,598.507019 826.670776,603.006958 824.009216,607.765442 
 C820.992493,613.158997 821.125610,618.227905 824.987061,623.558838 
 C828.578186,628.516479 827.356384,634.295166 825.209595,639.606689 
 C823.343018,644.224854 821.421692,648.908203 818.807190,653.117188 
 C814.354614,660.285156 815.502686,667.042847 819.517700,673.623169 
 C824.139465,681.197937 827.652344,689.080444 829.884583,697.749512 
 C831.354736,703.458618 831.174683,709.013306 830.755493,714.567505 
 C829.571350,730.255188 823.646362,743.966858 811.284119,754.062805 
 C795.692383,766.796204 777.526978,771.348816 757.560303,769.810425 
 C739.876526,768.447937 722.883850,763.807617 705.728455,759.811035 
 C699.193237,758.288513 692.782654,755.403931 685.722046,757.097473 
 C678.304260,758.876892 672.915161,762.805176 669.589417,769.895264 
 C665.874512,777.815125 663.877930,786.175781 662.390015,794.664307 
 C660.627747,804.717712 664.471619,813.598389 668.914551,822.308228 
 C671.483093,827.343506 673.696228,832.559448 676.101074,837.679138 
 C678.217468,842.184937 678.971191,846.729980 677.761414,851.729858 
 C675.481201,861.153564 676.415710,870.922913 675.098816,880.471436 
 C674.550415,884.448059 676.414429,887.334473 679.489441,889.923279 
 C690.730347,899.387024 690.691101,899.490723 681.478882,910.671204 
 C673.427063,920.443237 664.346130,929.326599 656.179871,938.949585 
 C647.480469,949.200806 636.453064,951.991089 624.043457,953.159424 
 C608.341736,954.637695 592.589050,955.879944 577.064880,958.824402 
 C573.349976,959.529053 570.161499,957.935730 566.802246,957.261841 
 C547.246521,953.338501 527.699585,949.351318 508.230103,945.023621 
 C486.698669,940.237671 465.182526,935.346436 443.790100,929.981079 
 C419.627991,923.921143 395.628632,917.215088 371.525452,910.916504 
 C350.783691,905.496338 329.979095,900.316956 309.234253,894.908508 
 C297.976074,891.973389 286.712585,889.018188 275.588562,885.622986 
 C268.265106,883.387817 262.907501,877.742798 257.081787,873.119263 
 C245.046326,863.567322 247.512482,864.332031 238.674713,874.205261 
 C234.457016,878.917114 230.125488,883.536987 225.666138,888.020081 
 C219.382843,894.336609 212.066422,893.134827 208.467606,884.902466 
 C203.752304,874.116089 199.496918,863.127319 195.111588,852.198364 
 C190.969727,841.875977 186.784454,831.567932 182.853775,821.164612 
 C176.933151,805.494751 171.824219,789.583862 167.255157,773.434082 
 C163.187973,759.058167 159.222839,744.686890 156.345352,730.053040 
 C152.248627,709.218750 149.888260,688.133972 147.286606,667.057007 
 C144.741425,646.437561 145.966919,625.807068 144.295471,605.303467 
 C142.667923,585.338379 142.618347,565.380493 142.259674,545.438660 
 C141.841110,522.165161 143.845978,498.895508 146.208740,475.737946 
 C147.737045,460.759003 150.562134,445.913849 152.979340,431.031860 
 C155.287399,416.821808 157.670731,402.603577 160.116287,388.428864 
 C162.243164,376.101135 164.733643,363.764069 167.107010,351.460480 
 C169.597641,338.549042 172.738480,325.843506 177.024399,313.541138 
 C183.040161,296.273407 190.307709,279.458038 200.959259,264.339417 
 C212.769684,247.575897 225.369003,231.437027 239.705261,216.781082 
 C259.398285,196.648911 281.566132,179.775482 306.987183,167.342041 
 C326.069305,158.008972 346.304565,152.137131 366.643585,146.346588 
 C383.273712,141.611969 399.668457,135.977417 416.594330,132.379761 
 C434.977753,128.472290 453.662598,126.272583 472.298859,123.942635 
 C508.052490,119.472618 544.019958,119.230225 579.978821,119.002464 
 C582.354004,118.987419 584.941895,119.095367 586.086365,116.566833 
 C588.537598,111.150833 593.676025,109.069595 598.434082,107.038231 
 C606.988464,103.386101 612.907776,97.247086 617.654053,89.585884 
 C618.698059,87.900795 619.912292,86.277100 621.277039,84.842964 
 C625.753113,80.139359 630.496460,81.340195 633.387756,87.124557 
 C637.641418,95.634239 640.266174,104.361366 639.440735,113.949379 
 C639.161926,117.187874 640.557251,117.901817 643.852844,118.141884 
 C658.439758,119.204491 669.970154,111.505478 682.091370,105.473518 
 C693.098145,99.996208 702.499695,92.435585 710.765076,83.324684 
 C715.730896,77.850891 722.375427,79.103134 724.943176,85.942101 
 C725.824707,88.289925 726.717102,90.617332 727.972961,92.727631 
 C733.745422,102.427055 730.568359,112.327080 728.853210,122.208221 
 C728.768860,122.694153 728.532227,123.158264 728.484192,123.644180 
 C728.455078,123.938087 728.652710,124.254440 728.781982,124.668037 
 C730.980042,125.173637 732.531006,123.591667 734.124756,122.658691 
 C751.437683,112.523483 770.231873,105.578453 788.439209,97.380806 
 C792.519348,95.543777 796.961792,94.902763 801.570984,94.812195 
 C817.715454,94.494942 833.857971,94.851166 850.017090,94.204605 
 C860.468567,93.786407 870.929138,94.130196 881.144287,91.673607 
 C887.260376,90.202789 893.148682,87.808266 899.214783,86.096062 
 C901.927551,85.330376 904.883240,85.029762 907.148621,87.483688 
 C908.700806,89.165108 909.476807,90.892372 908.343811,93.335625 
 C896.276794,119.356880 881.486877,143.493546 860.267334,163.201172 
 C855.124268,167.977768 849.071533,171.520065 843.127075,175.158752 
 C839.533142,177.358688 838.492798,179.780182 841.386169,183.179398 
 C851.950928,195.590866 862.621277,207.913681 873.076477,220.416580 
 C878.172119,226.510345 885.559814,228.441025 892.197876,231.770966 
 C893.683411,232.516190 895.308655,233.025589 896.697815,233.912598 
 C901.698853,237.105881 901.859375,240.306305 898.221375,244.833481 
 C890.301331,254.689011 879.165833,256.547119 867.745911,257.141327 
 C850.945740,258.015472 834.018311,258.117462 817.394104,255.783997 
 C801.262207,253.519623 786.346558,247.148819 773.359131,236.872864 
 C770.361938,234.501419 767.175110,232.355820 763.974487,230.260757 
 C762.270203,229.145142 760.142822,228.339294 758.332947,229.660355 
 C756.156006,231.249344 757.609680,233.432373 758.371277,235.114853 
 C763.928101,247.390137 769.595154,259.615814 775.272766,271.836060 
 C783.743835,290.068542 792.251892,308.283875 800.745239,326.505981 
 C801.724854,328.607758 802.711975,330.706055 803.878174,333.134186 
M339.078430,755.482239 
 C331.293793,750.309814 326.270691,743.135742 323.542358,734.246094 
 C322.672821,731.412903 320.846313,729.470093 317.619324,730.251282 
 C313.034912,731.360901 308.671112,732.918274 306.314240,737.606018 
 C302.670105,744.854065 298.880341,752.028870 295.234436,759.276062 
 C288.208099,773.242798 281.260132,787.248901 274.228088,801.212769 
 C272.900574,803.848877 271.129822,806.229919 267.643341,805.210083 
 C264.002502,804.145203 262.808929,801.262878 263.209686,797.784607 
 C264.215637,789.054382 265.434631,780.348877 266.465454,771.621277 
 C267.671265,761.412170 268.385406,751.128540 270.018372,740.990845 
 C271.708038,730.501160 272.308960,719.897522 274.128235,709.404602 
 C275.463165,701.705078 276.084747,693.541016 272.800568,685.827271 
 C270.788055,681.100464 268.510803,676.407227 267.219910,671.471924 
 C265.512177,664.943359 267.344604,658.534302 270.036835,652.557129 
 C272.941315,646.108826 271.980103,640.440552 268.166809,634.699951 
 C263.004425,626.928528 258.879822,618.674438 256.399200,609.541626 
 C253.230743,597.876343 249.515625,586.354980 245.850067,574.830750 
 C243.401260,567.131958 241.988846,559.329590 242.710281,551.260498 
 C243.729645,539.859375 244.744324,528.453003 246.084808,517.087097 
 C246.898117,510.191132 246.971512,503.000732 249.541809,496.552246 
 C255.013916,482.823608 261.108704,469.339355 267.108337,455.826019 
 C271.290222,446.406891 273.758118,436.949493 272.194244,426.373993 
 C271.107361,419.023926 271.640137,411.406555 271.763275,403.913788 
 C271.868805,397.494537 276.337067,394.994476 281.811584,398.194672 
 C286.552185,400.965912 291.078583,404.100769 295.772095,406.955811 
 C309.987274,415.602844 316.737091,429.976166 320.984436,444.602661 
 C324.810791,457.779419 324.325409,472.173706 326.163910,485.974762 
 C327.334564,494.762390 326.646484,503.626160 327.743256,512.362183 
 C329.586517,527.043823 331.524139,541.672058 330.434448,556.547424 
 C330.240265,559.198364 328.223511,561.612610 329.212738,564.693115 
 C332.472626,561.560608 335.394257,558.667725 338.415405,555.882935 
 C340.158569,554.276123 340.633850,552.359619 340.705231,550.070740 
 C341.088318,537.790833 339.281433,525.893372 334.404541,514.587219 
 C333.172485,511.730927 332.815125,508.911621 332.657104,505.889618 
 C331.927765,491.938965 330.927429,477.997162 331.033142,464.018860 
 C331.143921,449.372711 327.594055,434.742126 332.565582,420.060242 
 C335.848907,410.363922 337.521515,400.011902 341.717621,390.667328 
 C347.622772,377.516815 349.489716,363.653259 351.051086,349.641327 
 C352.873566,333.286346 350.933594,317.083710 348.398041,300.974792 
 C346.745575,290.476166 349.461639,287.988434 359.882812,291.655457 
 C375.553680,297.169861 387.035156,307.135162 393.920990,321.927124 
 C397.953094,330.588837 400.318085,340.017914 403.580688,349.048431 
 C410.147186,367.223785 412.307495,386.161957 413.084717,405.277374 
 C413.298157,410.526947 411.521454,415.830780 411.413239,421.125122 
 C411.098511,436.519592 407.382965,450.914673 400.190826,464.523773 
 C398.518555,467.688080 397.123199,471.405670 400.414124,474.884399 
 C403.458466,471.914246 404.527802,468.124451 406.465576,464.869598 
 C410.860962,457.486694 416.098083,450.364197 416.465240,441.308289 
 C416.755188,434.156250 416.688293,426.989990 416.804749,419.830475 
 C416.837097,417.841003 416.638733,415.795593 417.625305,413.948547 
 C422.417755,404.976135 424.890961,395.035675 429.476776,385.945221 
 C432.451447,380.048553 435.372406,373.820221 436.596222,367.411774 
 C438.765442,356.052704 436.114136,344.553864 435.056244,333.213257 
 C434.053680,322.465637 432.485107,311.776520 432.731995,300.958160 
 C432.803223,297.837280 433.340240,294.621552 434.278839,291.643188 
 C439.960541,273.614075 445.788452,255.603500 449.697235,237.108185 
 C452.457764,224.046082 456.243927,211.436279 462.016602,199.436417 
 C468.315430,186.342850 474.445648,173.283768 473.943970,158.169342 
 C473.836029,154.916763 476.352997,152.914536 479.962219,152.763596 
 C486.695526,152.481995 493.496155,157.243774 496.920959,164.624496 
 C501.177887,173.798462 505.251892,183.042465 508.607178,192.592300 
 C512.411926,203.421371 516.481995,214.441971 516.980774,225.808594 
 C517.933716,247.528793 518.013489,269.334778 514.614319,290.969574 
 C514.028381,294.698822 513.475708,298.628967 514.358398,302.594299 
 C517.176697,301.998657 518.268921,300.248383 519.481262,298.714111 
 C526.899109,289.326294 533.883240,279.558197 541.797485,270.611755 
 C548.548279,262.980591 550.431946,253.801453 550.183960,244.680450 
 C549.707458,227.156662 550.262085,209.709030 551.976868,192.337555 
 C552.680115,185.212372 553.479004,177.845718 556.867981,171.080841 
 C561.196838,162.439896 566.655884,154.474747 571.152771,145.948273 
 C573.590088,141.326767 576.677856,137.010696 578.222900,131.544815 
 C576.335083,131.299210 575.035217,130.972031 573.738647,130.984604 
 C543.454407,131.278168 513.183899,130.175034 482.921051,133.632080 
 C461.255554,136.107025 439.670319,139.225677 418.490540,143.855972 
 C381.947968,151.844849 345.733307,161.611450 311.567169,177.296173 
 C295.418335,184.709686 279.776093,193.258728 266.710907,205.798248 
 C256.982300,215.135452 247.296295,224.526764 238.337769,234.580399 
 C228.161545,246.000549 217.768600,257.319214 209.231430,270.064667 
 C199.942795,283.932098 193.776016,299.325134 188.221710,315.005646 
 C181.955322,332.696564 177.896408,350.978455 174.606506,369.331909 
 C171.103867,388.872131 167.343964,408.384918 164.782791,428.120789 
 C162.480240,445.863831 158.690231,463.383331 156.907028,481.264526 
 C154.758240,502.811615 153.536667,524.360962 152.976761,545.987366 
 C152.536423,562.995667 155.017654,579.920471 154.246902,596.907104 
 C153.474442,613.931396 155.526688,630.832947 156.397079,647.760681 
 C157.228485,663.929871 159.346390,680.070374 161.855453,696.151489 
 C164.204483,711.207092 165.679276,726.428772 169.535965,741.184570 
 C173.679703,757.038696 178.305084,772.784729 183.291107,788.395691 
 C188.134583,803.560242 193.368195,818.612610 199.661682,833.268799 
 C203.070999,841.208435 206.010178,849.355896 209.587204,857.215820 
 C212.710617,864.078979 214.456879,871.541321 218.519775,878.391479 
 C219.681702,877.423035 220.633713,876.776550 221.407898,875.961121 
 C227.937180,869.083923 234.385147,862.128906 240.958878,855.294739 
 C244.890961,851.206848 247.306198,851.048889 251.800156,854.520020 
 C255.884888,857.675171 259.717102,861.163269 263.860931,864.234314 
 C269.448486,868.375244 274.351654,873.728882 281.334564,875.596130 
 C299.287445,880.396912 317.285248,885.031067 335.283112,889.661865 
 C353.947021,894.463989 372.778473,898.682495 391.266907,904.080200 
 C409.901550,909.520630 428.754944,914.064331 447.551270,918.800964 
 C471.979218,924.956848 496.553467,930.541077 521.242981,935.653564 
 C535.826538,938.673462 550.286072,942.521729 564.912659,945.363831 
 C575.006226,947.325134 585.403381,947.915527 595.585815,944.722656 
 C598.989807,943.655273 599.993896,941.774048 599.147461,938.361694 
 C597.828125,933.043457 597.005005,927.596130 595.557861,922.316956 
 C593.244873,913.879639 592.141968,905.230957 588.612000,896.960144 
 C584.864136,888.178955 581.106567,878.884338 586.132568,868.639160 
 C588.638000,863.532043 585.562561,859.393494 579.971558,857.635498 
 C577.122559,856.739624 574.253723,855.900269 571.371460,855.117676 
 C551.168152,849.632263 530.798706,844.634827 510.783783,838.610718 
 C484.720642,830.766235 461.510010,816.096985 436.962219,804.607178 
 C417.375153,795.439331 398.128113,785.538269 378.813904,775.796631 
 C365.745575,769.205444 351.945709,764.049133 339.078430,755.482239 
M700.433838,746.424133 
 C702.637634,747.831909 705.256775,747.542053 707.660095,748.146667 
 C724.729248,752.441162 741.536316,758.160217 759.385803,758.461853 
 C771.077576,758.659607 782.863098,758.107361 793.521729,752.940002 
 C808.056763,745.893372 816.950439,733.878906 819.347717,717.897339 
 C820.341125,711.274963 821.102722,704.061401 818.824524,697.591309 
 C815.967773,689.478088 812.379272,681.588501 807.659607,674.246460 
 C803.331848,667.514099 802.714539,660.293884 806.516296,652.921387 
 C809.249939,647.620239 811.880005,642.260437 814.381531,636.846008 
 C817.245239,630.647766 816.801941,627.995300 811.958374,623.237122 
 C808.825073,620.159119 808.361206,617.190369 809.945801,612.940125 
 C812.070312,607.241882 816.325073,601.927124 813.706970,594.975098 
 C811.851562,590.048218 810.932983,584.772522 809.523315,579.672668 
 C808.089783,574.486389 811.896240,570.049500 817.035767,571.097046 
 C820.619141,571.827393 824.142883,572.852966 827.729431,573.564636 
 C850.959045,578.173523 866.533752,563.621094 862.216736,540.436951 
 C859.685181,526.841003 854.717896,513.692078 850.738525,500.374298 
 C847.140015,488.331177 843.408081,476.327759 839.710449,464.314392 
 C836.877441,455.110016 834.181763,445.832855 828.156921,438.038330 
 C823.271606,431.717957 818.811707,424.976349 813.301086,419.251282 
 C802.396423,407.922119 798.059143,394.780548 799.556519,379.263977 
 C800.146118,373.154602 799.267151,366.908478 799.782471,360.785706 
 C800.397339,353.478882 798.004700,347.029114 795.136963,340.701874 
 C790.283936,329.994354 785.085815,319.443481 780.059265,308.814270 
 C768.282166,283.910553 756.516113,259.001678 744.759155,234.088470 
 C743.327515,231.054825 741.078247,229.974197 737.853149,230.958939 
 C735.260132,231.750732 732.965759,233.001419 731.065247,234.993515 
 C726.242004,240.049301 721.432251,245.120499 716.507202,250.076248 
 C695.493469,271.220917 674.451294,292.337250 653.383362,313.427856 
 C651.317688,315.495789 649.343994,317.775116 649.369202,320.663422 
 C649.430725,327.696564 646.927185,334.388336 647.200378,341.481018 
 C647.432373,347.507172 645.223083,352.617126 639.796143,356.365112 
 C633.098328,360.990784 626.900269,366.337585 620.455505,371.333221 
 C617.774170,373.411652 616.481995,375.838531 616.686829,379.456146 
 C618.068970,403.868927 614.768494,427.833588 609.209900,451.532349 
 C608.271667,455.532257 607.058838,459.575287 605.253540,463.244476 
 C599.478943,474.981049 592.538086,486.148254 588.065369,498.518433 
 C585.837646,504.679688 582.296997,509.731079 576.392578,512.876709 
 C573.737000,514.291565 573.933899,516.684265 574.495117,518.984741 
 C575.629272,523.633179 576.900513,528.251953 578.249878,532.843323 
 C580.664368,541.058960 583.078369,549.277222 585.676636,557.435791 
 C588.791992,567.218079 592.034607,576.961304 595.340698,586.681152 
 C599.656982,599.370605 603.924866,612.080505 608.514709,624.671875 
 C614.032410,639.808594 619.872314,654.965820 621.056824,671.104553 
 C622.394775,689.333069 624.011597,707.594910 623.334167,725.936523 
 C623.093445,732.453430 624.952698,734.029541 631.413208,734.817932 
 C639.990112,735.864624 648.561340,737.032654 657.078003,738.483032 
 C671.310059,740.906616 685.497009,743.595032 700.433838,746.424133 
M504.039734,285.867401 
 C509.600830,247.074020 508.177124,209.290283 488.298981,173.264999 
 C486.879211,175.305725 487.099060,176.666290 487.181824,177.921936 
 C487.509338,182.891556 487.870941,187.860367 488.322632,192.820099 
 C489.580200,206.628876 487.858765,220.439056 488.386932,234.239624 
 C488.634644,240.713593 489.871399,247.170990 489.831696,253.630814 
 C489.693695,276.088593 486.883972,298.386444 485.028900,320.731415 
 C484.351562,328.889954 482.428802,337.040588 477.006378,343.959076 
 C468.761292,354.478973 459.732574,364.140961 449.252411,372.462280 
 C445.946442,375.087250 443.754120,378.586182 442.322418,382.645294 
 C440.053406,389.078339 438.074249,395.712463 434.930298,401.715698 
 C429.735870,411.634399 427.622040,421.930115 428.252167,433.010406 
 C428.622589,439.523315 428.116547,446.051422 425.559540,452.150421 
 C420.336090,464.609314 413.486847,476.179199 405.517334,487.058441 
 C403.674652,489.573853 401.130920,491.618103 398.729919,493.668549 
 C396.842316,495.280579 394.833649,496.692963 391.952545,495.576202 
 C388.914551,494.398621 388.078735,491.906891 387.957092,489.235443 
 C387.691986,483.413330 387.840881,477.573669 387.697266,471.744202 
 C387.612671,468.310120 388.441223,465.056549 389.928528,462.101318 
 C397.714355,446.631378 400.206665,429.856445 401.569519,412.961670 
 C402.872711,396.806061 401.278137,380.784119 396.855164,365.057892 
 C392.706055,350.305328 388.771454,335.482025 381.230713,322.014008 
 C376.702362,313.926178 370.665894,307.253204 360.481689,304.228149 
 C360.901794,308.702454 360.567078,312.649323 362.591675,316.191620 
 C366.049988,322.242279 369.096344,328.516510 373.201263,334.216766 
 C377.464996,340.137665 379.038544,346.879272 379.506714,354.269745 
 C380.166382,364.681641 382.741119,374.927216 382.759033,385.460358 
 C382.768097,390.773987 383.388641,396.085175 383.688202,401.399963 
 C384.532288,416.376709 384.825409,431.375031 383.698425,446.331085 
 C382.537842,461.732269 380.724640,477.083130 379.374878,492.471619 
 C378.388214,503.720581 376.398163,514.863831 376.021576,526.181335 
 C375.926361,529.042297 374.089569,531.370667 372.247345,533.623596 
 C365.601929,541.750793 357.230133,548.279236 351.017914,556.807007 
 C346.014099,563.676086 340.680908,570.240723 334.027405,575.678711 
 C328.378540,580.295593 322.871643,585.115601 317.623322,590.178894 
 C315.450623,592.274963 313.467926,594.688171 310.475311,593.016907 
 C307.083618,591.122681 306.851685,587.737366 308.241425,584.240112 
 C310.264252,579.149658 312.272339,574.052979 314.236755,568.939758 
 C316.733887,562.439941 320.561035,556.157043 320.492950,549.088379 
 C320.401764,539.626160 319.298157,530.167358 318.480988,520.719910 
 C317.306793,507.145111 315.392639,493.604156 314.893311,480.008392 
 C314.181274,460.621185 312.210663,441.831879 300.079254,425.626007 
 C296.100952,420.311615 291.974152,415.389465 284.200195,413.457886 
 C287.175995,420.927338 289.762787,427.547913 293.354858,433.706451 
 C297.001099,439.957886 300.053009,446.432495 300.070251,453.795715 
 C300.134247,481.127441 300.093567,508.459747 299.968170,535.791260 
 C299.942322,541.426941 300.363312,547.084656 298.921478,552.692383 
 C294.264374,570.805298 286.266785,588.049927 285.422272,607.177429 
 C285.097504,614.533691 284.023132,621.832703 284.782410,629.512085 
 C288.023163,628.156433 289.945068,625.982300 291.545593,623.763000 
 C297.200287,615.922363 304.311340,609.855103 312.635071,605.022217 
 C322.870789,599.079224 332.633698,592.603699 340.151794,583.092712 
 C346.435028,575.143982 352.437988,566.944397 359.494415,559.609131 
 C363.763397,555.171570 368.349121,552.092712 374.900177,553.612732 
 C377.318939,554.174011 379.808929,554.488770 382.284393,554.735779 
 C397.830383,556.286682 413.562500,556.790222 428.881622,559.574158 
 C445.322693,562.562012 459.626831,559.156128 472.491669,549.389099 
 C483.267090,541.208313 494.738770,537.921265 508.066589,540.709045 
 C512.512695,541.639099 516.912842,540.980042 520.853821,537.968384 
 C524.495239,535.185547 525.093750,532.674377 522.200500,528.886963 
 C516.125122,520.933838 508.335114,515.031250 500.034943,509.751587 
 C497.734863,508.288483 495.080963,506.996857 492.026672,507.420105 
 C485.070648,508.384064 477.999542,508.817017 471.078613,508.092407 
 C459.511475,506.881317 450.089630,511.138428 440.872406,517.395630 
 C431.804810,523.551331 422.705444,529.808960 412.639801,534.273621 
 C403.691376,538.242737 394.501007,541.668030 385.403503,545.298584 
 C382.761627,546.352905 379.763947,547.801514 377.631439,544.912964 
 C375.772980,542.395630 378.079041,540.173828 379.505768,538.287598 
 C387.750092,527.388367 394.737671,515.647461 402.297272,504.295380 
 C409.119995,494.049896 415.351624,483.407104 421.724915,472.867615 
 C424.558868,468.181122 427.856689,464.325775 433.413147,462.687683 
 C438.987030,461.044434 444.461090,459.034851 449.910156,457.001556 
 C463.169800,452.053772 476.040802,446.623260 481.885254,432.050507 
 C482.978821,429.323853 485.450897,428.159241 488.637939,427.722412 
 C494.283630,426.948578 499.920837,426.964996 505.573334,426.740448 
 C521.085815,426.124054 536.059143,423.200012 549.000061,414.087921 
 C559.347046,406.802307 569.782166,399.729004 580.763062,393.416382 
 C585.486206,390.701172 591.033264,388.074097 593.744812,383.493164 
 C600.488220,372.100739 610.001831,363.973297 620.584045,356.489899 
 C627.573669,351.547119 634.293823,345.997772 634.877136,336.080994 
 C635.292664,329.016846 631.638611,324.776672 624.657043,325.894226 
 C601.359192,329.623505 578.068604,333.407471 554.823425,337.447510 
 C551.003052,338.111481 547.636597,338.240906 545.450745,334.947632 
 C543.061951,331.348663 546.068542,328.701660 548.127563,326.189240 
 C550.908875,322.795563 553.779114,319.496765 555.771240,314.784882 
 C550.058899,315.638580 545.001831,316.439056 539.930481,317.135925 
 C536.754456,317.572327 533.773621,317.080719 532.312622,313.806488 
 C530.971069,310.799988 532.286072,308.329010 534.547424,306.244690 
 C545.898254,295.782593 557.263245,285.335663 568.574158,274.830597 
 C570.999268,272.578308 573.767212,271.178101 577.019775,270.917816 
 C586.312744,270.174072 595.622009,268.873932 604.909912,268.989929 
 C622.223328,269.206207 638.993408,266.047699 655.766663,262.475616 
 C664.776062,260.557007 673.509460,258.274872 680.159607,251.131775 
 C685.255554,245.658051 690.723206,240.529297 696.050049,235.272018 
 C698.299500,233.051956 700.487549,230.747147 703.579041,229.620743 
 C719.051758,223.983337 734.353271,217.725769 751.262024,217.781906 
 C754.282715,217.791931 757.189087,216.837967 759.067749,214.050140 
 C764.459167,206.049301 770.022705,198.159286 775.221375,190.035522 
 C778.579834,184.787491 781.355835,184.031403 787.272522,186.226791 
 C794.158752,188.781921 800.490662,193.673019 808.554749,192.027435 
 C814.243774,190.866531 819.962708,189.846039 825.632324,188.599442 
 C828.587708,187.949661 829.128540,186.372894 827.718384,183.447327 
 C824.564270,176.903381 818.086731,176.475052 812.506042,175.490402 
 C793.156860,172.076477 774.376953,166.198349 755.058228,162.720551 
 C753.055298,162.359970 750.974182,161.618484 751.019348,159.058884 
 C751.067688,156.319885 753.329163,156.091339 755.334839,155.956375 
 C757.991577,155.777588 760.673340,155.947571 763.325806,155.735748 
 C773.928955,154.888992 784.970947,155.204315 794.575500,150.194183 
 C808.003906,143.189346 822.069092,137.576126 835.466431,130.583618 
 C846.133301,125.016212 854.573853,116.568481 863.269226,107.943695 
 C860.518799,106.522079 858.217346,106.313820 855.919373,106.268341 
 C836.764893,105.889259 817.624695,106.519966 798.487366,107.204948 
 C796.856384,107.263336 795.145020,107.620804 793.640076,108.246185 
 C783.658386,112.394196 773.650330,116.491714 763.790161,120.916168 
 C750.727539,126.777603 738.151245,133.602158 725.736755,140.735199 
 C719.686584,144.211441 715.727417,141.825272 715.680176,134.790482 
 C715.623901,126.415001 717.605835,118.298271 719.209961,110.154190 
 C720.178711,105.235771 720.277405,100.723679 716.054565,96.786247 
 C713.619141,102.175240 713.187805,107.531799 713.086853,112.976448 
 C712.988159,118.299057 711.725952,123.483170 707.832092,127.174149 
 C702.591919,132.141312 697.004517,136.812088 691.172791,141.071533 
 C675.996948,152.155731 661.391541,163.962662 646.638794,175.584229 
 C639.593689,181.134079 632.918091,187.081528 626.711243,193.590103 
 C611.817017,209.208374 598.330627,226.123062 582.634766,241.008774 
 C571.522278,251.547684 561.156250,262.797882 551.763062,274.938904 
 C542.616028,286.761627 533.752502,298.832611 523.250549,309.540649 
 C520.172668,312.678864 516.647278,314.982910 512.222961,316.027771 
 C504.978729,317.738586 502.162109,315.695526 502.512329,308.164276 
 C502.844330,301.024506 503.477722,293.898773 504.039734,285.867401 
M583.706421,796.645935 
 C584.740906,779.553345 585.726379,762.457458 586.845459,745.370422 
 C587.116150,741.237122 585.833862,738.888428 581.482422,737.924805 
 C575.972717,736.704590 570.615417,734.801392 565.183472,733.223572 
 C535.796326,724.687073 510.926941,709.157837 490.903717,685.852783 
 C482.717957,676.325317 473.547424,668.242004 459.371338,670.851257 
 C457.467194,671.201721 455.397919,670.558411 453.403168,670.534180 
 C449.517822,670.486877 446.812073,672.075439 445.357391,675.935547 
 C443.370392,681.208252 441.078064,686.367310 438.866577,691.553772 
 C434.542023,701.695740 431.668304,703.452332 420.208374,703.061707 
 C413.970734,702.849121 410.398407,705.172668 407.929565,711.048279 
 C405.591980,716.611511 402.813934,718.636169 397.216980,717.967834 
 C390.278839,717.139282 383.332520,716.483093 376.384613,715.796509 
 C372.213928,715.384399 369.532990,716.534729 366.772491,719.458740 
 C360.375336,726.234741 353.763977,732.818237 347.044342,739.276794 
 C342.223511,743.910339 342.135864,744.436096 348.151917,747.432678 
 C383.465912,765.022156 418.751709,782.669128 454.137543,800.113098 
 C468.763489,807.323120 482.755310,815.959717 498.087738,821.568420 
 C520.573547,829.794067 543.927673,835.255371 566.932922,841.822632 
 C572.615479,843.444763 578.174866,845.578735 585.168213,846.164368 
 C582.852478,829.739380 583.892029,813.671509 583.706421,796.645935 
M611.343750,788.748230 
 C611.251404,786.752319 611.130249,784.757324 611.071106,782.760437 
 C610.036926,747.839233 612.966064,712.884460 609.824036,677.961426 
 C608.788696,666.453552 606.390930,655.242065 603.032471,644.423157 
 C596.707825,624.049011 588.850403,604.155701 581.948547,583.953796 
 C578.262146,573.163391 574.377686,562.439575 571.458923,551.370239 
 C568.557617,540.367554 564.516907,529.670349 561.374756,518.724609 
 C558.731079,509.515717 555.611084,500.430878 554.521301,490.783173 
 C553.872498,485.038910 552.086365,479.423096 550.811523,473.749542 
 C545.118835,480.503204 540.511658,487.481384 535.414734,494.032837 
 C540.514038,500.712219 545.029785,507.334503 550.274841,513.317444 
 C556.746582,520.699646 558.727905,529.626221 560.268921,538.762146 
 C561.234192,544.484985 561.250854,550.374878 562.326904,556.070129 
 C563.714172,563.413330 563.685303,570.582642 561.851868,577.810608 
 C561.215942,580.317261 560.737671,582.686523 557.679138,583.353882 
 C554.615356,584.022339 552.711731,582.741577 550.992676,580.285278 
 C547.255920,574.945862 543.055908,569.969788 537.402222,566.523804 
 C528.062195,560.830933 518.366577,557.086914 507.118042,560.513000 
 C500.775574,562.444824 494.291687,563.933777 487.831848,565.453674 
 C478.979675,567.536499 471.230164,570.892273 464.766571,578.011841 
 C456.964905,586.605103 448.532898,594.714417 439.620911,602.269592 
 C435.235077,605.987671 430.333984,609.032654 425.879791,612.530823 
 C426.768860,614.119568 427.878571,613.948975 428.819183,613.950012 
 C435.985229,613.957642 443.171509,614.226501 450.311188,613.775818 
 C456.124451,613.408875 460.660004,615.477478 464.821869,619.135376 
 C473.814240,627.038879 482.846771,634.896606 491.847473,642.790710 
 C499.471008,649.476929 507.163086,656.089172 514.646301,662.929443 
 C518.413757,666.373230 517.884766,667.929321 513.321960,670.134033 
 C510.534119,671.481018 507.386444,670.938660 503.669891,672.039429 
 C505.489075,672.786865 506.395477,673.175598 507.313721,673.534119 
 C521.616699,679.116516 536.908997,680.989624 551.570801,685.366089 
 C570.204224,690.928223 585.498962,700.124756 595.215637,717.367737 
 C596.337524,719.358582 598.152649,720.969238 598.320557,723.525818 
 C599.129761,735.843750 598.557861,748.153198 597.956909,760.444946 
 C597.331970,773.227417 595.864319,785.975281 595.463013,798.760193 
 C595.051147,811.886475 595.998596,824.993896 596.856934,838.127563 
 C598.262878,859.640930 599.394287,881.114746 603.141174,902.404480 
 C605.229797,914.272583 607.841431,926.012695 610.284729,937.798096 
 C611.329285,942.837219 615.163513,942.211243 618.480591,941.886658 
 C622.638062,941.479797 622.139954,938.227234 621.401184,935.385071 
 C619.659424,928.684631 617.344604,922.106750 616.012573,915.331665 
 C612.806580,899.023804 610.726807,882.486084 609.985107,865.927185 
 C608.846252,840.498596 609.690002,815.036438 611.343750,788.748230 
M425.737152,665.194031 
 C432.482788,662.279114 439.476898,660.114319 446.677826,658.729492 
 C450.949829,657.907898 453.231598,654.811157 453.821106,651.327820 
 C454.361359,648.135376 450.210968,648.178406 448.132874,646.798584 
 C447.595703,646.441772 446.884064,646.355286 446.264343,646.114258 
 C429.013428,639.407166 410.599426,638.457764 392.632477,635.307007 
 C386.096527,634.160828 379.547150,632.984924 372.954498,632.292847 
 C362.215302,631.165344 351.449768,629.967468 340.669434,629.632629 
 C326.375305,629.188721 311.358521,624.407227 298.511658,635.239685 
 C298.276459,635.438049 297.842804,635.387634 297.577789,635.566223 
 C292.849976,638.751282 286.679871,639.953979 283.946838,645.870178 
 C278.104736,658.516785 275.851074,671.113586 283.463776,683.962952 
 C285.407257,687.243469 287.208069,690.909302 286.840210,694.798157 
 C285.729675,706.538391 284.873199,718.357666 282.742706,729.929871 
 C280.838501,740.272644 281.108093,750.633179 280.268402,761.041809 
 C286.093445,753.420288 289.988831,744.804565 294.045471,736.367126 
 C298.426819,727.254211 303.814209,720.408447 314.851013,719.603394 
 C318.432587,719.342102 321.664154,716.920532 323.507965,713.438660 
 C330.124908,700.943359 337.809845,689.080261 345.024536,676.938782 
 C346.876495,673.822205 349.567108,672.063904 353.066681,671.737061 
 C362.850708,670.823425 372.774628,669.926880 382.462830,671.024719 
 C395.003357,672.445740 407.351471,675.748718 419.030579,667.922424 
 C420.785095,666.746643 423.009308,666.271912 425.737152,665.194031 
M613.898010,137.483902 
 C616.953125,132.522644 620.107544,127.619278 623.038879,122.585915 
 C626.950989,115.868088 630.383545,109.011169 625.709656,101.178688 
 C624.763794,100.952072 624.046082,101.497894 623.636902,102.183350 
 C618.528076,110.741272 610.060242,114.645836 601.414062,118.304596 
 C598.197021,119.665947 596.126953,121.994240 594.448730,125.089722 
 C587.321655,138.235931 579.973267,151.262207 572.701538,164.330002 
 C569.554504,169.985489 564.930664,175.491486 564.679626,181.856049 
 C564.083252,196.976807 559.859497,211.795258 561.223572,227.043793 
 C561.519531,230.351898 561.312622,233.705902 561.318481,237.038788 
 C561.327698,242.273514 561.320923,247.508286 561.320923,252.879349 
 C563.850464,252.586517 564.849854,251.168259 565.932922,249.996384 
 C578.815308,236.057159 591.459473,221.890945 604.584595,208.185028 
 C614.591125,197.735687 623.527344,186.238510 634.492859,176.741714 
 C654.769409,159.181030 676.442017,143.362259 697.973328,127.425491 
 C706.386230,121.198517 706.250488,113.252739 707.154846,105.029366 
 C704.547058,103.671829 703.110535,105.175011 701.543945,105.969986 
 C669.199524,122.382767 639.525818,142.904236 610.816284,164.916397 
 C608.314148,166.834839 605.538513,169.092102 602.365723,166.211151 
 C599.379822,163.499954 600.696167,160.430191 602.316101,157.583786 
 C606.019714,151.076187 609.801880,144.613251 613.898010,137.483902 
M362.567749,534.047363 
 C367.429077,530.912109 370.400055,526.764343 370.815033,520.718018 
 C371.689972,507.969513 372.433380,495.184601 374.080048,482.525085 
 C375.433044,472.122955 375.363647,461.644836 376.787048,451.252289 
 C377.934662,442.873535 378.624451,434.332458 378.400757,425.892975 
 C378.167999,417.109924 377.033203,408.343933 377.022949,399.516357 
 C377.016418,393.873138 376.745514,388.237061 376.352264,382.585571 
 C375.566833,371.298279 373.477478,360.178375 372.707642,348.909424 
 C372.215942,341.711975 368.343048,336.524811 362.755188,331.488617 
 C362.595184,333.554321 362.352692,334.870636 362.413269,336.172852 
 C363.451599,358.501282 358.625671,379.727142 350.158234,400.207031 
 C344.534424,413.809204 340.921173,427.695587 341.172150,442.587189 
 C341.365570,454.063660 342.204742,465.508698 342.634094,476.964325 
 C343.226440,492.768005 342.166809,508.960449 349.021088,523.993347 
 C350.053619,526.257935 350.252197,528.763428 350.413483,531.290161 
 C350.648895,534.978882 351.351959,538.637817 351.887543,542.541565 
 C356.512787,540.757568 358.817322,537.060181 362.567749,534.047363 
M256.723389,524.893677 
 C255.001205,536.729370 253.473877,548.588745 254.209366,560.584595 
 C254.390503,563.539307 254.966034,566.524536 255.779312,569.376221 
 C259.270020,581.616089 261.896423,594.114807 266.399628,606.051453 
 C269.226044,613.543396 271.418274,621.393738 277.298035,628.101196 
 C277.524384,626.889038 277.793365,626.268799 277.725586,625.687744 
 C276.770599,617.497070 279.586487,609.593140 279.471802,601.406250 
 C279.399109,596.217407 280.744415,590.963440 282.505615,585.866394 
 C285.809601,576.304382 289.212128,566.700012 291.424896,556.855042 
 C294.841614,541.653625 294.058044,526.115967 293.240479,510.658722 
 C292.439514,495.515381 292.103638,480.292938 293.478210,465.257568 
 C294.305450,456.209412 292.548889,448.325745 289.411438,440.294434 
 C288.367767,437.622864 287.230591,435.045502 284.492645,432.878754 
 C284.294586,436.638916 284.263000,439.784515 283.014069,442.777557 
 C279.052216,452.271759 275.150391,461.791351 271.279968,471.323303 
 C264.383209,488.308441 255.615082,504.787323 256.723389,524.893677 
M458.502930,246.849411 
 C458.265381,247.471848 457.958771,248.077255 457.800507,248.719238 
 C454.470978,262.224030 450.300079,275.504944 446.804901,288.947784 
 C444.576996,297.516388 442.544617,306.516632 443.879333,315.532013 
 C446.020264,329.993591 447.970459,344.451508 448.314026,359.088013 
 C448.347565,360.516296 447.863525,362.134766 449.351715,363.258484 
 C451.327362,363.738678 452.381897,362.263367 453.583496,361.195465 
 C461.094208,354.520142 467.144409,346.532990 473.239471,338.645477 
 C476.950439,333.843201 478.627441,328.075562 478.247986,321.810089 
 C478.098633,319.344116 478.466217,316.834259 478.728149,314.358063 
 C480.037201,301.982758 482.252930,289.632355 482.545715,277.235321 
 C483.147156,251.766953 482.690887,226.274323 482.760864,200.791946 
 C482.773621,196.142380 481.529175,191.843781 480.450806,187.487427 
 C468.778870,205.566299 462.674683,225.420181 458.502930,246.849411 
M867.449341,231.089325 
 C859.717468,222.199142 851.961975,213.342667 844.817749,203.946457 
 C836.835876,193.448380 831.705139,191.900360 819.393738,196.537598 
 C809.154846,200.394119 799.584839,199.962692 790.232239,194.260925 
 C788.678711,193.313858 786.945679,192.655960 785.279053,191.901001 
 C783.748230,191.207565 782.077209,191.329147 781.161621,192.640121 
 C775.769653,200.360901 770.520142,208.181183 765.063049,216.202454 
 C771.306885,220.834671 776.752563,225.192093 782.511780,229.085724 
 C797.605103,239.289841 814.334229,244.901611 832.553772,245.308716 
 C838.168518,245.434174 843.874451,244.299850 849.473206,245.237518 
 C858.008240,246.666931 866.430298,245.434280 874.830261,244.483276 
 C877.852478,244.141113 881.280945,243.640030 884.206116,241.136749 
 C878.532227,237.777023 873.274536,234.663742 867.449341,231.089325 
M673.686218,901.481201 
 C673.349121,898.120544 670.735657,896.451965 668.530090,894.445740 
 C666.325439,892.440430 663.879272,890.541626 663.750488,887.160767 
 C663.394714,877.821167 663.527954,868.261536 665.798889,859.394043 
 C669.436951,845.188904 663.513367,834.310181 656.800537,822.617310 
 C653.910706,825.953247 651.910461,829.203918 648.963928,831.704773 
 C641.351196,838.166321 634.030212,844.972473 626.392273,851.402832 
 C622.454895,854.717773 620.809753,858.459106 621.445374,863.638550 
 C622.619141,873.202637 623.214661,882.821472 624.959717,892.335693 
 C627.563965,906.534668 629.374084,920.882080 633.205750,934.843567 
 C634.775452,940.563171 637.567688,941.414001 642.036316,937.396851 
 C645.008362,934.725159 648.028809,932.078430 650.748169,929.160156 
 C658.784058,920.536194 667.450134,912.432678 673.686218,901.481201 
M573.076294,313.510376 
 C570.280579,316.618866 567.205566,319.520935 564.964172,323.868591 
 C571.853149,323.936890 577.719910,322.041718 583.727783,321.031097 
 C601.083313,318.111572 618.498474,315.545319 635.716980,311.845734 
 C638.562378,311.234344 641.130005,310.159454 643.199524,308.109009 
 C646.624512,304.715546 649.993347,301.265320 653.376221,297.829468 
 C670.649231,280.286072 687.930115,262.750305 705.166504,245.170944 
 C706.346497,243.967468 708.323975,243.215256 708.190491,240.958084 
 C706.306274,240.269775 705.309387,241.601669 704.313965,242.594879 
 C699.721497,247.176971 695.212219,251.842239 690.645874,256.450684 
 C683.956116,263.202148 678.153687,270.464935 667.343140,272.012543 
 C656.076965,273.625397 645.103271,277.828400 633.728882,278.906525 
 C616.520264,280.537689 599.202576,281.013184 581.933533,282.019623 
 C580.290710,282.115356 578.337769,281.997467 577.083374,282.822174 
 C569.058716,288.098083 562.367920,294.890320 555.853210,302.022400 
 C559.507751,301.916473 562.922607,301.424072 566.311584,300.792908 
 C570.373413,300.036407 574.936401,298.187958 577.631226,302.760162 
 C580.326477,307.333008 575.784912,309.887299 573.076294,313.510376 
M623.124634,775.500000 
 C623.651917,796.871765 622.207458,818.209534 621.087341,840.605347 
 C628.077942,836.039001 632.596130,830.582947 638.204041,826.538940 
 C643.520508,822.705078 645.532166,817.407104 646.761658,811.417725 
 C648.755798,801.704041 650.622925,791.953125 653.018433,782.336182 
 C655.631042,771.847473 659.812622,761.959656 666.355530,753.497681 
 C665.776978,752.037903 664.818909,751.649536 663.841370,751.504333 
 C651.837402,749.721313 639.829956,747.961121 627.817566,746.235474 
 C624.166443,745.711060 623.164429,747.826904 623.173096,751.006287 
 C623.194580,758.837402 623.145508,766.668762 623.124634,775.500000 
M383.792786,608.574829 
 C378.313293,607.134827 372.708282,606.625488 367.087311,606.040100 
 C356.500305,604.937683 345.897522,603.789551 335.412476,602.006531 
 C331.464844,601.335266 328.253479,601.525818 325.053711,603.751709 
 C319.311157,607.746521 313.373444,611.483704 307.832184,615.738586 
 C302.262817,620.015076 297.213440,624.924988 291.834686,631.521118 
 C297.951416,628.591614 302.800171,626.436218 307.479309,623.960144 
 C309.925201,622.665771 312.390900,622.092346 315.099548,621.937256 
 C327.971893,621.199890 340.686035,623.347717 353.467773,624.121765 
 C369.393250,625.086243 385.174683,627.654602 400.965576,629.977905 
 C415.269318,632.082397 429.700592,633.354065 443.434753,638.442810 
 C451.689819,641.501526 460.151306,644.002380 468.514893,646.769409 
 C470.997864,647.590881 473.463104,648.508972 476.143799,647.015381 
 C474.957825,643.492432 472.382782,641.089905 469.974152,639.095276 
 C465.051605,635.018860 461.391449,628.878601 454.548523,627.692444 
 C449.662994,626.845703 444.711639,625.928345 439.667114,626.190857 
 C429.799805,626.704651 420.051117,625.221558 410.278473,624.254456 
 C406.400085,623.870605 403.012878,622.300720 399.980408,619.662842 
 C395.227020,615.528137 390.680511,611.016235 383.792786,608.574829 
M558.036743,440.587402 
 C564.189331,430.482849 571.378113,420.992859 576.951782,410.080688 
 C574.281860,409.749237 573.082703,411.130829 571.800903,411.981354 
 C564.309814,416.951965 556.965637,422.144409 549.466736,427.102966 
 C545.043945,430.027435 540.923340,434.138214 535.550293,434.657074 
 C527.431091,435.441101 519.726440,438.776642 511.433075,438.222595 
 C507.861389,437.984009 506.029755,439.887054 506.411896,443.398315 
 C507.347046,451.991425 503.413422,458.762970 498.869659,465.511810 
 C496.598785,468.884766 493.132996,471.441223 491.233490,476.497040 
 C503.508362,475.990997 512.966309,482.150177 522.942871,486.941467 
 C525.734985,488.282379 527.244568,486.184937 528.583191,484.211975 
 C538.299622,469.890686 547.996033,455.555817 558.036743,440.587402 
M510.474915,493.115570 
 C506.568085,490.773529 502.345642,488.958069 497.857086,488.639526 
 C487.575897,487.909821 477.239929,485.946472 466.985870,487.259613 
 C456.316254,488.625946 445.296997,490.083252 436.186066,496.417786 
 C422.808105,505.719116 409.856842,515.635620 396.763794,525.344116 
 C393.385620,527.849060 391.529480,531.405273 389.729675,535.803589 
 C397.667358,535.133484 403.935120,531.934814 410.082306,528.826782 
 C423.312988,522.137329 435.391571,513.486267 447.990814,505.718933 
 C450.575836,504.125336 453.134918,502.775696 456.257141,502.525421 
 C466.726135,501.686157 477.242401,502.254547 487.693085,501.569580 
 C497.798645,500.907227 505.530609,504.999634 512.979736,511.213257 
 C523.097778,519.653137 531.827087,529.358093 540.210083,539.398376 
 C546.646912,534.445129 546.893433,533.308960 543.271057,526.536682 
 C542.722778,525.511597 542.294678,524.422974 541.771240,523.383789 
 C534.984070,509.910461 525.778748,498.899872 510.474915,493.115570 
M837.038513,136.585419 
 C830.700500,139.524826 824.338684,142.414185 818.030090,145.415482 
 C808.870850,149.772980 800.320618,155.411026 790.668945,158.713043 
 C787.041992,159.953918 783.197266,160.558640 778.662537,161.641922 
 C779.950562,162.205429 780.355774,162.473465 780.797119,162.562607 
 C793.632996,165.155533 806.521484,167.505844 819.162598,170.988297 
 C824.197083,172.375229 828.778259,172.006470 833.417603,168.968811 
 C854.558411,155.126526 871.658997,137.577850 883.616272,115.225090 
 C885.740723,111.253700 888.440552,107.451553 889.345459,103.004578 
 C887.115173,101.693314 885.626587,102.656082 884.072388,103.159508 
 C876.080505,105.748299 869.857117,110.963585 863.856812,116.571999 
 C855.842529,124.062836 848.145325,131.995514 837.038513,136.585419 
M565.308716,449.817413 
 C562.115051,454.760376 560.218323,459.583008 561.755005,465.993500 
 C563.155396,471.835327 566.755554,476.035156 569.485107,480.896454 
 C572.581970,486.411804 578.926514,486.628143 582.659058,481.437500 
 C583.528259,480.228790 584.196838,478.868835 584.895935,477.545807 
 C592.751160,462.678925 599.713806,447.589661 601.587769,430.491974 
 C602.886841,418.639679 604.858826,406.865143 604.884705,394.903748 
 C604.888062,393.347015 605.444092,391.563965 603.432678,389.683350 
 C589.451599,408.775421 579.076294,429.964264 565.308716,449.817413 
M378.646332,681.723206 
 C371.506195,682.160217 364.369629,682.678650 357.224060,682.991699 
 C354.607208,683.106323 352.663971,684.101440 351.545319,686.355286 
 C346.294189,696.935608 339.673187,706.710754 333.672455,716.848145 
 C331.561676,720.414062 331.771240,723.840881 333.318787,727.317017 
 C335.359253,731.900269 337.640076,732.269287 341.171906,728.780518 
 C348.508148,721.533752 355.779907,714.220886 363.177887,707.037903 
 C366.300446,704.006042 370.173401,702.218079 374.555084,703.123840 
 C380.657776,704.385315 386.854492,703.535706 392.972412,704.442139 
 C396.329010,704.939453 399.694855,703.285706 400.868164,700.230957 
 C403.683929,692.899841 409.147675,691.648376 416.054626,691.822937 
 C425.001343,692.049133 432.285339,684.420471 432.272705,675.318420 
 C427.232025,675.960754 422.765747,678.254150 418.120575,679.987244 
 C409.942078,683.038696 402.059875,687.541138 392.758972,683.795471 
 C388.582581,682.113464 383.974854,682.027344 378.646332,681.723206 
M412.187256,607.691956 
 C427.486877,598.400452 441.211548,587.255310 453.792755,573.726868 
 C452.083313,572.928711 451.492645,572.407166 450.912445,572.418579 
 C440.583252,572.621277 430.373199,571.101868 420.111877,570.344055 
 C417.493042,570.150696 415.736542,571.452698 414.239105,573.427673 
 C408.422089,581.099731 402.692566,588.843811 396.667542,596.350037 
 C393.682465,600.068970 394.468964,602.464905 398.297150,604.591370 
 C400.185364,605.640137 402.120239,606.662415 403.826508,607.967957 
 C406.461243,609.983887 408.957092,610.004517 412.187256,607.691956 
M396.453979,579.002625 
 C398.889923,575.946045 402.179749,573.370911 402.313721,569.025940 
 C401.224365,567.829590 399.954071,567.393494 398.630096,567.251953 
 C390.049530,566.334290 381.460266,565.498047 372.881470,564.564819 
 C370.485901,564.304199 368.570129,565.166626 367.104584,566.875427 
 C360.827209,574.194397 354.601685,581.558350 348.412842,588.952393 
 C346.515930,591.218689 347.305389,592.625183 350.108093,592.933594 
 C359.691132,593.988098 369.276123,595.027405 378.868500,595.991882 
 C381.410309,596.247437 383.757263,595.942932 385.497162,593.520691 
 C388.893341,588.792480 392.494598,584.211548 396.453979,579.002625 
M551.617004,715.863464 
 C562.378967,720.233398 573.434326,723.540833 584.774780,725.450439 
 C585.586914,724.024475 584.915161,723.164185 584.514099,722.293152 
 C578.332458,708.867798 566.188599,702.809509 553.359863,698.360779 
 C543.209167,694.840698 532.507446,692.903015 522.038086,690.312134 
 C518.017639,689.317139 513.963257,688.459229 509.924347,687.538818 
 C521.450623,700.463501 535.527466,709.002625 551.617004,715.863464 
M422.283722,491.708130 
 C422.221924,492.032654 422.126801,492.356110 422.115936,492.682312 
 C422.112000,492.800537 422.384949,493.042908 422.404236,493.030487 
 C423.365753,492.412842 424.446808,491.907684 425.242249,491.116302 
 C434.607147,481.798889 446.788910,478.294037 459.105316,477.150543 
 C472.577942,475.899658 482.374084,470.070099 489.934265,459.504242 
 C493.302155,454.797363 495.247284,449.552551 494.889801,443.622864 
 C494.755707,441.397949 494.676880,439.133606 491.997375,438.438751 
 C489.195618,437.712219 488.065735,439.778534 487.216827,441.797638 
 C483.224518,451.292786 475.451691,456.869141 466.770782,461.365845 
 C459.372833,465.197968 451.351929,467.551544 443.454712,470.094330 
 C437.749603,471.931335 432.781982,474.157349 429.873016,479.946686 
 C427.874146,483.924805 424.984680,487.455444 422.283722,491.708130 
M631.223511,137.642700 
 C633.364624,135.730286 636.367798,134.792496 637.913513,132.040085 
 C631.869568,129.427155 628.865479,131.612839 626.297546,140.574097 
 C627.941589,139.604187 629.295654,138.805313 631.223511,137.642700 
z"
      />
    </SvgIcon>
  );
};

export default DanteIcon;
