const ROLES = {
  SUPER_ADMIN: "SUPER_ADMIN",
  EMPLEADOS_CONSULTAS: "EMPLEADOS_CONSULTAS",
  EMPLEADOS_CONFIGURACIONES: "EMPLEADOS_CONFIGURACIONES",
  PROVEEDORES_CONFIGURACIONES: "PROVEEDORES_CONFIGURACIONES",
  PROVEEDORES_CONSULTAS: "PROVEEDORES_CONSULTAS",
  PROVEEDORES_REGISTRO_PAGOS: "PROVEEDORES_REGISTRO_PAGOS",
  SUELDOS_CONFIGURACIONES: "SUELDOS_CONFIGURACIONES",
  SUELDOS_CONSULTAS: "SUELDOS_CONSULTAS",
  BECAS_CONSULTAS: "BECAS_CONSULTAS",
  BECAS_CONFIGURACIONES: "BECAS_CONFIGURACIONES",
  ESPACIOS_CONSULTAS: "ESPACIOS_CONSULTAS",
  ESPACIOS_CONFIGURACIONES: "ESPACIOS_CONFIGURACIONES",
  UNIDAD_DANTE_CONSULTAS: "UNIDAD_DANTE_CONSULTAS",
  UNIDAD_DANTE_CONFIGURACIONES: "UNIDAD_DANTE_CONFIGURACIONES",
  AUSENCIAS_CONSULTAS: "AUSENCIAS_CONSULTAS",
  AUSENCIAS_CONFIGURACIONES: "AUSENCIAS_CONFIGURACIONES",
  PLANTA_FUNCIONAL_CONSULTAS: "PLANTA_FUNCIONAL_SECUNDARIA_CONSULTAS",
  PLANTA_FUNCIONAL_CONFIGURACIONES:
    "PLANTA_FUNCIONAL_SECUNDARIA_CONFIGURACIONES",
  PLANTA_FUNCIONAL_PRIMARIA_CONSULTAS: "PLANTA_FUNCIONAL_PRIMARIA_CONSULTAS",
  PLANTA_FUNCIONAL_PRIMARIA_CONFIGURACIONES:
    "PLANTA_FUNCIONAL_PRIMARIA_CONFIGURACIONES",
  DOCUMENTOS_CONSULTAS: "DOCUMENTOS_CONSULTAS",
  DOCUMENTOS_CONFIGURACIONES: "DOCUMENTOS_CONFIGURACIONES",
};

const verifyRoles = (userRoles, requiredRoles) => {
  const isSuperAdmin = userRoles?.find(
    (userRole) => userRole === ROLES.SUPER_ADMIN,
  );
  if (isSuperAdmin) {
    return true;
  }
  const hasSomeRequiredRole = userRoles?.some((userRole) =>
    requiredRoles.includes(userRole),
  );
  return hasSomeRequiredRole;
};

module.exports = { ROLES, verifyRoles };
