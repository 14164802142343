import { Suspense, lazy } from "react";
import { Routes as ReactRouterRoutes, Route } from "react-router-dom";
import Layout from "../components/commons/Layout";
import Loader from "../components/commons/Loader";
import NuevoUsuario from "./commons/NuevoUsuario";
import ModificarUsuario from "./commons/ModificarUsuario";
import ModificarContraseña from "./commons/ModificarContraseña";
import Login from "./commons/Login";
import Menu from "./commons/Menu";
import NotFound from "./commons/NotFound";
import { COMMONS_ROUTES } from "./commons/constants";
import { PROVEEDORES_BASE_ROUTE } from "./proveedores/constants";
import { SUELDOS_BASE_ROUTE } from "./sueldos/constants";
import { EMPLEADOS_BASE_ROUTE } from "./empleados/constants";
import { BECAS_BASE_ROUTE } from "./becas/constants";
import { ESPACIOS_BASE_ROUTE } from "./espacios/constants";
import { UNIDAD_DANTE_BASE_ROUTE } from "./unidadDante/constants";
import { REEMPLAZOS_BASE_ROUTE } from "./reemplazos/constants";
import { PLANTA_FUNCIONAL_BASE_ROUTE } from "./plantaFuncional/constants";
import { DOCUMENTOS_BASE_ROUTE } from "./documentos/constants";
import ProtectedRoute from "../components/commons/ProtectedRoute/ProtectedRoute";
import { ROLES } from "dante-alighieri-roles";
import { useUserState } from "../providers/UserProvider";

const Proveedores = lazy(() => import("./proveedores"));
const Sueldos = lazy(() => import("./sueldos"));
const Empleados = lazy(() => import("./empleados"));
const Becas = lazy(() => import("./becas"));
const Espacios = lazy(() => import("./espacios"));
const UnidadDante = lazy(() => import("./unidadDante"));
const Reemplazos = lazy(() => import("./reemplazos"));
const PlantaFuncional = lazy(() => import("./plantaFuncional"));
const Documentos = lazy(() => import("./documentos"));

const Routes = () => {
  const { isLoading } = useUserState();

  if (isLoading) {
    return <Loader />;
  }

  return (
    <ReactRouterRoutes>
      <Route path={COMMONS_ROUTES.LOGIN} element={<Layout />}>
        <Route index element={<Login />} />
        <Route
          path={COMMONS_ROUTES.MENU}
          element={
            <ProtectedRoute>
              <Menu />
            </ProtectedRoute>
          }
        />
        <Route
          path={COMMONS_ROUTES.NUEVO_USUARIO}
          element={
            <ProtectedRoute requiredRoles={[ROLES.SUPER_ADMIN]}>
              <NuevoUsuario />
            </ProtectedRoute>
          }
        />
        <Route
          path={COMMONS_ROUTES.MODIFICAR_USUARIO}
          element={
            <ProtectedRoute requiredRoles={[ROLES.SUPER_ADMIN]}>
              <ModificarUsuario />
            </ProtectedRoute>
          }
        />
        <Route
          path={COMMONS_ROUTES.MODIFICAR_CONTRASENA}
          element={
            <ProtectedRoute>
              <ModificarContraseña />
            </ProtectedRoute>
          }
        />
        {/* systems */}
        <Route
          path={`${PROVEEDORES_BASE_ROUTE}/*`}
          element={
            <Suspense fallback={<Loader />}>
              <ProtectedRoute
                requiredRoles={[
                  ROLES.PROVEEDORES_CONFIGURACIONES,
                  ROLES.PROVEEDORES_CONSULTAS,
                ]}
              >
                <Proveedores />
              </ProtectedRoute>
            </Suspense>
          }
        />
        <Route
          path={`${SUELDOS_BASE_ROUTE}/*`}
          element={
            <Suspense fallback={<Loader />}>
              <ProtectedRoute
                requiredRoles={[
                  ROLES.SUELDOS_CONFIGURACIONES,
                  ROLES.SUELDOS_CONSULTAS,
                ]}
              >
                <Sueldos />
              </ProtectedRoute>
            </Suspense>
          }
        />
        <Route
          path={`${EMPLEADOS_BASE_ROUTE}/*`}
          element={
            <Suspense fallback={<Loader />}>
              <ProtectedRoute requiredRoles={[ROLES.EMPLEADOS_CONSULTAS]}>
                <Empleados />
              </ProtectedRoute>
            </Suspense>
          }
        />
        <Route
          path={`${BECAS_BASE_ROUTE}/*`}
          element={
            <Suspense fallback={<Loader />}>
              <ProtectedRoute
                requiredRoles={[
                  ROLES.BECAS_CONSULTAS,
                  ROLES.BECAS_CONFIGURACIONES,
                ]}
              >
                <Becas />
              </ProtectedRoute>
            </Suspense>
          }
        />
        <Route
          path={`${ESPACIOS_BASE_ROUTE}/*`}
          element={
            <Suspense fallback={<Loader />}>
              <ProtectedRoute
                requiredRoles={[
                  ROLES.ESPACIOS_CONSULTAS,
                  ROLES.ESPACIOS_CONFIGURACIONES,
                ]}
              >
                <Espacios />
              </ProtectedRoute>
            </Suspense>
          }
        />
        <Route
          path={`${UNIDAD_DANTE_BASE_ROUTE}/*`}
          element={
            <Suspense fallback={<Loader />}>
              <ProtectedRoute
                requiredRoles={[
                  ROLES.UNIDAD_DANTE_CONSULTAS,
                  ROLES.UNIDAD_DANTE_CONFIGURACIONES,
                ]}
              >
                <UnidadDante />
              </ProtectedRoute>
            </Suspense>
          }
        />
        <Route
          path={`${REEMPLAZOS_BASE_ROUTE}/*`}
          element={
            <Suspense fallback={<Loader />}>
              <ProtectedRoute
                requiredRoles={[
                  ROLES.AUSENCIAS_CONSULTAS,
                  ROLES.AUSENCIAS_CONFIGURACIONES,
                ]}
              >
                <Reemplazos />
              </ProtectedRoute>
            </Suspense>
          }
        />
        <Route
          path={`${PLANTA_FUNCIONAL_BASE_ROUTE}/*`}
          element={
            <Suspense fallback={<Loader />}>
              <ProtectedRoute
                requiredRoles={[
                  ROLES.PLANTA_FUNCIONAL_CONSULTAS,
                  ROLES.PLANTA_FUNCIONAL_CONFIGURACIONES,
                  ROLES.PLANTA_FUNCIONAL_PRIMARIA_CONSULTAS,
                  ROLES.PLANTA_FUNCIONAL_PRIMARIA_CONFIGURACIONES,
                ]}
              >
                <PlantaFuncional />
              </ProtectedRoute>
            </Suspense>
          }
        />
        <Route
          path={`${DOCUMENTOS_BASE_ROUTE}/*`}
          element={
            <Suspense fallback={<Loader />}>
              <ProtectedRoute
                requiredRoles={[
                  ROLES.DOCUMENTOS_CONSULTAS,
                  ROLES.DOCUMENTOS_CONFIGURACIONES,
                ]}
              >
                <Documentos />
              </ProtectedRoute>
            </Suspense>
          }
        />
        {/* url doesnt exist */}
        <Route path="*" element={<NotFound />} />
      </Route>
    </ReactRouterRoutes>
  );
};

export default Routes;
