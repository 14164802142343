import { Navigate } from "react-router-dom";
import { verifyRoles } from "dante-alighieri-roles";
import { useUserState } from "../../../providers/UserProvider";
import { COMMONS_ROUTES } from "../../../routes/commons/constants";

export default function ProtectedRoute({ children, requiredRoles }) {
  const { isLoggedIn, roles } = useUserState();

  if (!isLoggedIn) {
    return <Navigate to={COMMONS_ROUTES.LOGIN} replace />;
  }
  if (requiredRoles && !verifyRoles(roles, requiredRoles)) {
    return <Navigate to={COMMONS_ROUTES.MENU} replace />;
  }

  return children;
}
