export const EMPLEADOS_BASE_ROUTE = "/empleados";

const CONSULTAS_BASE_ROUTE = `${EMPLEADOS_BASE_ROUTE}/consultas`;
const CONFIGURACIONES_BASE_ROUTE = `${EMPLEADOS_BASE_ROUTE}/configuraciones`;

export const EMPLEADOS_ROUTES = {
  MENU: EMPLEADOS_BASE_ROUTE,
  CONSULTAS_CARGO: `${CONSULTAS_BASE_ROUTE}/cargo`,
  CONSULTAS_ESCUELA_JARDIN: `${CONSULTAS_BASE_ROUTE}/jardin`,
  CONSULTAS_ESCUELA_PRIMARIA: `${CONSULTAS_BASE_ROUTE}/primaria`,
  CONSULTAS_ESCUELA_SECUNDARIA: `${CONSULTAS_BASE_ROUTE}/secundaria`,
  CONFIGURACIONES_EMPLEADO: `${CONFIGURACIONES_BASE_ROUTE}/agregar-empleado`,
};
