import {
  createContext,
  useEffect,
  useContext,
  useCallback,
  useState,
} from "react";
import axios from "axios";
import { configure } from "axios-hooks";
import SESSION_STORAGE_KEYS from "../../constants/sessionStorage";
import { COMMONS_ROUTES } from "../../routes/commons/constants";

const axiosInstance = axios.create({ baseURL: "/api" });

const UserStateContext = createContext();
const UserUpdaterContext = createContext();

function UserProvider({ children }) {
  const [userState, setUserState] = useState({
    isLoggedIn: false,
    username: null,
    roles: [],
    isLoading: !!sessionStorage.getItem(SESSION_STORAGE_KEYS.TOKEN),
  });

  useEffect(() => {
    axiosInstance.interceptors.request.use(
      async (config) => {
        const token = sessionStorage.getItem(SESSION_STORAGE_KEYS.TOKEN);
        if (token) {
          config.headers = {
            Authorization: `Bearer ${token}`,
          };
        }
        return config;
      },
      (error) => Promise.reject(error),
    );
    axiosInstance.interceptors.response.use(
      (response) => response,
      async (error) => {
        if (error?.response?.status === 401) {
          sessionStorage.removeItem(SESSION_STORAGE_KEYS.TOKEN);
          setUserState({
            isLoggedIn: false,
            username: null,
            roles: [],
            isLoading: false,
          });
          window.location.replace(COMMONS_ROUTES.LOGIN);
        }
        return Promise.reject(error);
      },
    );
    configure({ axios: axiosInstance });
  }, []);

  useEffect(() => {
    const storedToken = sessionStorage.getItem(SESSION_STORAGE_KEYS.TOKEN);
    if (storedToken) {
      axiosInstance
        .get("/usuarios/me", {
          headers: { Authorization: `Bearer ${storedToken}` },
        })
        .then(({ data: user }) => {
          sessionStorage.setItem(SESSION_STORAGE_KEYS.TOKEN, user.token);
          setUserState({
            isLoggedIn: true,
            username: user.username,
            roles: user.roles,
            isLoading: false,
          });
        })
        .catch(() => {
          sessionStorage.removeItem(SESSION_STORAGE_KEYS.TOKEN);
          setUserState({
            isLoggedIn: false,
            username: null,
            roles: [],
            isLoading: false,
          });
          window.location.replace(COMMONS_ROUTES.LOGIN);
        });
    }
  }, []);

  return (
    <UserStateContext.Provider value={userState}>
      <UserUpdaterContext.Provider value={setUserState}>
        {children}
      </UserUpdaterContext.Provider>
    </UserStateContext.Provider>
  );
}

function useUserState() {
  const userState = useContext(UserStateContext);
  if (typeof userState === "undefined") {
    throw new Error("useUserState must be used within a UserProvider");
  }
  return userState;
}

function useUserUpdater() {
  const userUpdater = useContext(UserUpdaterContext);
  if (typeof userUpdater === "undefined") {
    throw new Error("useUserUpdater must be used within a UserProvider");
  }
  const login = useCallback(
    ({ username, roles }) =>
      userUpdater({
        isLoggedIn: true,
        username,
        roles,
        isLoading: false,
      }),
    [userUpdater],
  );
  const logout = useCallback(
    () =>
      userUpdater({
        isLoggedIn: false,
        username: null,
        roles: [],
        isLoading: false,
      }),
    [userUpdater],
  );
  return { login, logout };
}

export { UserProvider, useUserState, useUserUpdater };
