import { useEffect, useState } from "react";
import useAxios from "axios-hooks";
import styled from "@emotion/styled";
import { useNavigate } from "react-router-dom";
import {
  Paper,
  IconButton,
  OutlinedInput,
  InputLabel,
  InputAdornment,
  FormControl,
  Alert,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import LoadingButton from "@mui/lab/LoadingButton";
import TransferList from "../../../components/commons/TransferList/TransferList";
import {
  useSnackbarUpdater,
  SNACKBAR_SEVERITY,
} from "../../../providers/SnackbarProvider";
import { COMMONS_ROUTES } from "../../../routes/commons/constants";

const StyledPaper = styled(Paper)`
  max-width: 600px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  padding: 25px;
  gap: 30px;
`;

const ModificarUsuario = () => {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [buttonClicked, setButtonClicked] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [username, setUsername] = useState("");
  const [user, setUser] = useState(null);
  const [password, setPassword] = useState(null);
  const [checked, setChecked] = useState([]);
  const setSnackbar = useSnackbarUpdater();
  const [{ data: usuarios }, fetchUsuarios] = useAxios("/usuarios/allUsers");
  const [, putNewUserData] = useAxios(
    {
      url: `/usuarios/${user?.id}`,
      method: "PUT",
    },
    { manual: true },
  );
  const [, deleteUser] = useAxios(
    {
      url: `/usuarios/${user?.id}`,
      method: "DELETE",
    },
    { manual: true },
  );
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  useEffect(() => {
    user && setChecked(user.roles);
  }, [user]);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleUserChange = (event) => {
    setUser(event.target.value);
    setUsername(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setErrorMessage("");
    if (buttonClicked === "edit") {
      const formData = Object.fromEntries(new FormData(event.target).entries());
      checked.length ? (formData.roles = checked) : delete formData.roles;
      if (formData.password === "") {
        delete formData.password;
      }
      delete formData.username;
      try {
        await putNewUserData({ data: formData });
        setSnackbar({
          open: true,
          message: "El usuario se ha modificado con exito!",
          severity: SNACKBAR_SEVERITY.SUCCESS,
        });
        navigate(COMMONS_ROUTES.MENU);
      } catch (error) {
        setErrorMessage(
          error?.response?.data?.message ||
            "Ha ocurrido un error, por favor intentalo mas tarde.",
        );
      }
    } else {
      try {
        await deleteUser();
        setSnackbar({
          open: true,
          message: "El usuario se ha eliminado con exito!",
          severity: SNACKBAR_SEVERITY.SUCCESS,
        });
        fetchUsuarios();
      } catch (error) {
        setErrorMessage(
          error?.response?.data?.message ||
            "Ha ocurrido un error, por favor intentalo mas tarde.",
        );
      }
    }
  };

  return (
    <StyledPaper component="form" onSubmit={handleSubmit} autocomplete="off">
      {errorMessage && (
        <Alert
          sx={{ marginBottom: (theme) => theme.spacing(2) }}
          severity="error"
        >
          {errorMessage}
        </Alert>
      )}
      <FormControl variant="outlined">
        <InputLabel htmlFor="outlined-usuario">Seleccione usuario</InputLabel>
        <Select
          id="outlined-usuario"
          label="Seleccione usuario"
          variant="outlined"
          type="text"
          name="username"
          required
          value={username}
          onChange={handleUserChange}
        >
          {usuarios?.map((usuario) => (
            <MenuItem value={usuario} key={usuario.id}>
              {usuario.username}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl variant="outlined">
        <InputLabel htmlFor="password">Nueva contraseña</InputLabel>
        <OutlinedInput
          id="password"
          label="Nueva contraseña"
          type={showPassword ? "text" : "password"}
          inputProps={{
            autocomplete: "new-password",
          }}
          name="password"
          value={password}
          onChange={(event) => setPassword(event.target.value)}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge="end"
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          }
        />
      </FormControl>
      <FormControl>
        <Typography mb={2}>Seleccione roles:</Typography>
        <TransferList
          optionsList={[
            "SUPER_ADMIN",
            "PROVEEDORES_CONSULTAS",
            "PROVEEDORES_CONFIGURACIONES",
            "PROVEEDORES_REGISTRO_PAGOS",
            "EMPLEADOS_CONSULTAS",
            "EMPLEADOS_CONFIGURACIONES",
            "SUELDOS_CONSULTAS",
            "SUELDOS_CONFIGURACIONES",
            "BECAS_CONSULTAS",
            "BECAS_CONFIGURACIONES",
            "ESPACIOS_CONSULTAS",
            "ESPACIOS_CONFIGURACIONES",
            "UNIDAD_DANTE_CONSULTAS",
            "UNIDAD_DANTE_CONFIGURACIONES",
            "AUSENCIAS_CONSULTAS",
            "AUSENCIAS_CONFIGURACIONES",
            "PLANTA_FUNCIONAL_PRIMARIA_CONSULTAS",
            "PLANTA_FUNCIONAL_PRIMARIA_CONFIGURACIONES",
            "PLANTA_FUNCIONAL_SECUNDARIA_CONSULTAS",
            "PLANTA_FUNCIONAL_SECUNDARIA_CONFIGURACIONES",
            "DOCUMENTOS_CONSULTAS",
            "DOCUMENTOS_CONFIGURACIONES",
          ]}
          checked={checked}
          setChecked={setChecked}
        />
      </FormControl>
      <LoadingButton
        onClick={(e) => setButtonClicked(e.target.value)}
        variant="contained"
        type="submit"
        value="edit"
        disabled={!username || !checked.length}
      >
        MOFIDICAR USUARIO
      </LoadingButton>
      <LoadingButton
        onClick={(e) => setButtonClicked(e.target.value)}
        variant="contained"
        color="error"
        type="submit"
        value="delete"
        disabled={!username}
      >
        ELIMINAR USUARIO
      </LoadingButton>
    </StyledPaper>
  );
};

export default ModificarUsuario;
