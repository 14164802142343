import { useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import AccountCircle from "@mui/icons-material/AccountCircle";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import Box from "@mui/material/Box";
import { verifyRoles, ROLES } from "dante-alighieri-roles";
import Link from "../Link";
import DanteIcon from "../icons/DanteIcon/DanteIcon";
import { COMMONS_ROUTES } from "../../../routes/commons/constants";
import { useUserState, useUserUpdater } from "../../../providers/UserProvider";
import NavItem from "./NavItem";
import HeaderBar from "./HeaderBar";
import { items } from "./Items";
import { StyledLink, LogoText, StyledContainer } from "./Styles";
import SESSION_STORAGE_KEYS from "../../../constants/sessionStorage";

function Layout() {
  const navigate = useNavigate();
  const { isLoggedIn, roles } = useUserState();
  const { logout } = useUserUpdater();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    logout();
    navigate(COMMONS_ROUTES.LOGIN);
    sessionStorage.removeItem(SESSION_STORAGE_KEYS.TOKEN);
    handleClose();
  };

  return (
    <div>
      <AppBar
        sx={{
          display: { md: "block", xs: "none" },
        }}
      >
        <Toolbar>
          <StyledLink
            to={isLoggedIn ? COMMONS_ROUTES.MENU : COMMONS_ROUTES.LOGIN}
          >
            <DanteIcon />
            <LogoText variant="h6">
              DANTE
              <br />
              ROSARIO
            </LogoText>
          </StyledLink>
          <Box
            sx={{
              display: "flex",
              marginLeft: (theme) => theme.spacing(4),
              flexGrow: 1,
              gap: (theme) => theme.spacing(2),
            }}
          >
            {items.map((item, index) =>
              verifyRoles(roles, item.roles) ? (
                <NavItem to={item.path} key={index}>
                  {item.name}
                </NavItem>
              ) : null,
            )}
          </Box>
          {isLoggedIn ? (
            <div>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
              >
                <AccountCircle />
                {!anchorEl ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />}
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                {verifyRoles(roles, [ROLES.SUPER_ADMIN]) ? (
                  <Link to={COMMONS_ROUTES.NUEVO_USUARIO}>
                    <MenuItem>Crear usuario</MenuItem>
                  </Link>
                ) : null}
                {verifyRoles(roles, [ROLES.SUPER_ADMIN]) ? (
                  <Link to={COMMONS_ROUTES.MODIFICAR_USUARIO}>
                    <MenuItem>Modificar usuario</MenuItem>
                  </Link>
                ) : null}
                {!roles.includes(ROLES.SUPER_ADMIN) ? (
                  <Link to={COMMONS_ROUTES.MODIFICAR_CONTRASENA}>
                    <MenuItem>Modificar contraseña</MenuItem>
                  </Link>
                ) : null}
                <MenuItem onClick={handleLogout}>Cerrar sesión</MenuItem>
              </Menu>
            </div>
          ) : null}
        </Toolbar>
      </AppBar>
      <Toolbar
        sx={{
          display: { md: "block", xs: "none" },
        }}
      />
      <HeaderBar />
      <StyledContainer fixed>
        <Outlet />
      </StyledContainer>
    </div>
  );
}

export default Layout;
