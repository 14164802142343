import { ROLES } from "dante-alighieri-roles";
import { PROVEEDORES_ROUTES } from "../../../routes/proveedores/constants";
import { SUELDOS_ROUTES } from "../../../routes/sueldos/constants";
import { EMPLEADOS_ROUTES } from "../../../routes/empleados/constants";
import { BECAS_ROUTES } from "../../../routes/becas/constants";
import { ESPACIOS_ROUTES } from "../../../routes/espacios/constants";
import { UNIDAD_DANTE_ROUTES } from "../../../routes/unidadDante/constants";
import { REEMPLAZOS_ROUTES } from "../../../routes/reemplazos/constants";
import { PLANTA_FUNCIONAL_SHARED_ROUTES } from "../../../routes/plantaFuncional/constants";
import { DOCUMENTOS_ROUTES } from "../../../routes/documentos/constants";

export const items = [
  {
    name: "Proveedores",
    path: PROVEEDORES_ROUTES.MENU,
    roles: [ROLES.PROVEEDORES_CONFIGURACIONES, ROLES.PROVEEDORES_CONSULTAS],
  },
  {
    name: "Sueldos",
    path: SUELDOS_ROUTES.MENU,
    roles: [ROLES.SUELDOS_CONFIGURACIONES, ROLES.SUELDOS_CONSULTAS],
  },
  {
    name: "Empleados",
    path: EMPLEADOS_ROUTES.MENU,
    roles: [ROLES.EMPLEADOS_CONSULTAS],
  },
  {
    name: "Becas",
    path: BECAS_ROUTES.MENU,
    roles: [ROLES.BECAS_CONSULTAS, ROLES.BECAS_CONFIGURACIONES],
  },
  {
    name: "Espacios",
    path: ESPACIOS_ROUTES.MENU,
    roles: [ROLES.ESPACIOS_CONSULTAS, ROLES.ESPACIOS_CONFIGURACIONES],
  },
  {
    name: "Unidad Dante",
    path: UNIDAD_DANTE_ROUTES.MENU,
    roles: [ROLES.UNIDAD_DANTE_CONSULTAS, ROLES.UNIDAD_DANTE_CONFIGURACIONES],
  },
  {
    name: "Ausencias",
    path: REEMPLAZOS_ROUTES.MENU,
    roles: [ROLES.AUSENCIAS_CONSULTAS, ROLES.AUSENCIAS_CONFIGURACIONES],
  },
  {
    name: "Planta Funcional",
    path: PLANTA_FUNCIONAL_SHARED_ROUTES.MENU,
    roles: [
      ROLES.PLANTA_FUNCIONAL_CONSULTAS,
      ROLES.PLANTA_FUNCIONAL_CONFIGURACIONES,
    ],
  },
  {
    name: "Documentos",
    path: DOCUMENTOS_ROUTES.MENU,
    roles: [ROLES.DOCUMENTOS_CONSULTAS, ROLES.DOCUMENTOS_CONFIGURACIONES],
  },
];
