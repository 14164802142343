import styled from "@emotion/styled";
import { Typography, Container } from "@mui/material";
import Link from "../Link";

export const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
  gap: ${(props) => props.theme.spacing(1)};
`;

export const LogoText = styled(Typography)`
  margin-right: ${(props) => props.theme.spacing(2)};
  font-weight: ${(props) => props.theme.typography.fontWeightBold};
  color: ${(props) => props.theme.palette.common.white};
  line-height: 1;
`;

export const StyledContainer = styled(Container)`
  margin-top: ${(props) => props.theme.spacing(1)};
  margin-bottom: ${(props) => props.theme.spacing(1)};
`;
