import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useAxios from "axios-hooks";
import styled from "@emotion/styled";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import LoadingButton from "@mui/lab/LoadingButton";
import Alert from "@mui/material/Alert";
import { useUserState, useUserUpdater } from "../../../providers/UserProvider";
import { COMMONS_ROUTES } from "../../../routes/commons/constants";
import SESSION_STORAGE_KEYS from "../../../constants/sessionStorage";

const StyledPaper = styled(Paper)`
  max-width: 600px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  padding: 25px;
  gap: 30px;
  margin-top: 60px;
`;

const Login = () => {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const { isLoggedIn } = useUserState();
  const { login } = useUserUpdater();
  const [{ loading }, postLogin] = useAxios(
    {
      url: "/auth/login",
      method: "POST",
    },
    { manual: true },
  );

  useEffect(() => {
    if (isLoggedIn) {
      navigate(COMMONS_ROUTES.MENU);
    }
  }, [isLoggedIn, navigate]);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setErrorMessage("");
    const formData = Object.fromEntries(new FormData(event.target).entries());
    try {
      const { data } = await postLogin({ data: formData });
      if (data?.token) {
        const newUser = {
          username: data.username,
          roles: data.roles,
        };
        login(newUser);
        sessionStorage.setItem(SESSION_STORAGE_KEYS.TOKEN, data.token);
        navigate(COMMONS_ROUTES.MENU);
      }
    } catch (error) {
      setErrorMessage(
        error?.response?.data?.message ||
          "Ha ocurrido un error, por favor intentalo mas tarde.",
      );
    }
  };

  return (
    <StyledPaper component="form" onSubmit={handleSubmit}>
      {errorMessage && (
        <Alert
          sx={{ marginBottom: (theme) => theme.spacing(2) }}
          severity="error"
        >
          {errorMessage}
        </Alert>
      )}
      <TextField
        id="outlined-basic"
        label="Usuario"
        variant="outlined"
        type="text"
        name="username"
        required
        value={username}
        onChange={(event) => setUsername(event.target.value)}
      />
      <FormControl variant="outlined" required>
        <InputLabel htmlFor="outlined-password">Contraseña</InputLabel>
        <OutlinedInput
          id="outlined-password"
          label="Contraseña"
          type={showPassword ? "text" : "password"}
          name="password"
          value={password}
          onChange={(event) => setPassword(event.target.value)}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge="end"
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          }
        />
      </FormControl>
      <LoadingButton
        variant="contained"
        type="submit"
        disabled={!username || !password}
        loading={loading}
      >
        INICIAR SESION
      </LoadingButton>
    </StyledPaper>
  );
};

export default Login;
