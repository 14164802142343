export const PROVEEDORES_BASE_ROUTE = "/proveedores";

const CONSULTAS_BASE_ROUTE = `${PROVEEDORES_BASE_ROUTE}/consultas`;
const CONFIGURACIONES_BASE_ROUTE = `${PROVEEDORES_BASE_ROUTE}/configuraciones`;

export const PROVEEDORES_ROUTES = {
  MENU: PROVEEDORES_BASE_ROUTE,
  // consultas
  CONSULTAS_RUBROS: `${CONSULTAS_BASE_ROUTE}/rubros`,
  CONSULTAS_GASTOS_PROVEEDOR: `${CONSULTAS_BASE_ROUTE}/gastos-proveedor`,
  CONSULTAS_GASTOS_GENERALES: `${CONSULTAS_BASE_ROUTE}/gastos-generales`,
  CONSULTAS_PAGOS_PENDIENTES: `${CONSULTAS_BASE_ROUTE}/pagos-pendientes`,
  CONSULTAS_COMPRAS_OBRA: `${CONSULTAS_BASE_ROUTE}/compras-obra`,
  MOVIMIENTOS_FONDOS: `${CONSULTAS_BASE_ROUTE}/movimientos-fondos`,
  // configuraciones
  CONFIGURACIONES_RUBROS: `${CONFIGURACIONES_BASE_ROUTE}/rubros`,
  CONFIGURACIONES_CATEGORIAS: `${CONFIGURACIONES_BASE_ROUTE}/categorias`,
  CONFIGURACIONES_PROVEEDORES: `${CONFIGURACIONES_BASE_ROUTE}/proveedores`,
  CONFIGURACIONES_SOLICITANTES: `${CONFIGURACIONES_BASE_ROUTE}/solicitantes`,
  CONFIGURACIONES_APROBADORES: `${CONFIGURACIONES_BASE_ROUTE}/aprobadores`,
  CONFIGURACIONES_OBRAS_EVENTOS: `${CONFIGURACIONES_BASE_ROUTE}/obras-eventos`,
  CONFIGURACIONES_FACTURA_NUEVA: `${CONFIGURACIONES_BASE_ROUTE}/factura-nueva`,
  CONFIGURACIONES_MEDIOS_PAGO: `${CONFIGURACIONES_BASE_ROUTE}/medios-pago`,
  CONFIGURACIONES_TIPOS_MOV: `${CONFIGURACIONES_BASE_ROUTE}/tipos-movimientos`,
  MODIFICACIONES_FACTURA: `${CONFIGURACIONES_BASE_ROUTE}/modificar-factura`,
  MODIFICACIONES_MOVIMIENTO: `${CONFIGURACIONES_BASE_ROUTE}/modificar-movimiento`,
  CARGAR_MOVIMIENTO: `${CONFIGURACIONES_BASE_ROUTE}/cargar-movimiento`,
};
