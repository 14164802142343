import styled from "@emotion/styled";
import { ROLES, verifyRoles } from "dante-alighieri-roles";
import { useUserState } from "../../../providers/UserProvider";
import MenuOption from "../../../components/commons/MenuOption";
import { PROVEEDORES_ROUTES } from "../../proveedores/constants";
import { SUELDOS_ROUTES } from "../../sueldos/constants";
import { EMPLEADOS_ROUTES } from "../../empleados/constants";
import { BECAS_ROUTES } from "../../becas/constants";
import { ESPACIOS_ROUTES } from "../../espacios/constants";
import { UNIDAD_DANTE_ROUTES } from "../../unidadDante/constants";
import { REEMPLAZOS_ROUTES } from "../../reemplazos/constants";
import { PLANTA_FUNCIONAL_SHARED_ROUTES } from "../../plantaFuncional/constants";
import { DOCUMENTOS_ROUTES } from "../../documentos/constants";
import { useMediaQuery } from "@mui/material";

const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  gap: ${(props) => props.theme.spacing(3)};
`;

const Menu = () => {
  const { roles } = useUserState();
  const matches = useMediaQuery("(min-width:900px)");

  return (
    <Container style={{ marginTop: matches ? "200px" : "20px" }}>
      {verifyRoles(roles, [
        ROLES.PROVEEDORES_CONFIGURACIONES,
        ROLES.PROVEEDORES_CONSULTAS,
        ROLES.PROVEEDORES_REGISTRO_PAGOS,
      ]) ? (
        <MenuOption to={PROVEEDORES_ROUTES.MENU}>Proveedores</MenuOption>
      ) : null}
      {verifyRoles(roles, [
        ROLES.SUELDOS_CONFIGURACIONES,
        ROLES.SUELDOS_CONSULTAS,
      ]) ? (
        <MenuOption to={SUELDOS_ROUTES.MENU}>Sueldos</MenuOption>
      ) : null}
      {verifyRoles(roles, [ROLES.EMPLEADOS_CONSULTAS]) ? (
        <MenuOption to={EMPLEADOS_ROUTES.MENU}>Empleados</MenuOption>
      ) : null}
      {verifyRoles(roles, [
        ROLES.BECAS_CONSULTAS,
        ROLES.BECAS_CONFIGURACIONES,
      ]) ? (
        <MenuOption to={BECAS_ROUTES.MENU}>Becas</MenuOption>
      ) : null}
      {verifyRoles(roles, [
        ROLES.ESPACIOS_CONSULTAS,
        ROLES.ESPACIOS_CONFIGURACIONES,
      ]) ? (
        <MenuOption to={ESPACIOS_ROUTES.MENU}>Espacios</MenuOption>
      ) : null}
      {verifyRoles(roles, [
        ROLES.UNIDAD_DANTE_CONSULTAS,
        ROLES.UNIDAD_DANTE_CONFIGURACIONES,
      ]) ? (
        <MenuOption to={UNIDAD_DANTE_ROUTES.MENU}>Unidad Dante</MenuOption>
      ) : null}
      {verifyRoles(roles, [
        ROLES.AUSENCIAS_CONSULTAS,
        ROLES.AUSENCIAS_CONFIGURACIONES,
      ]) ? (
        <MenuOption to={REEMPLAZOS_ROUTES.MENU}>Ausencias</MenuOption>
      ) : null}
      {verifyRoles(roles, [
        ROLES.PLANTA_FUNCIONAL_CONSULTAS,
        ROLES.PLANTA_FUNCIONAL_CONFIGURACIONES,
        ROLES.PLANTA_FUNCIONAL_PRIMARIA_CONFIGURACIONES,
        ROLES.PLANTA_FUNCIONAL_PRIMARIA_CONSULTAS,
      ]) ? (
        <MenuOption to={PLANTA_FUNCIONAL_SHARED_ROUTES.MENU}>
          Planta Funcional
        </MenuOption>
      ) : null}
      {verifyRoles(roles, [
        ROLES.DOCUMENTOS_CONSULTAS,
        ROLES.DOCUMENTOS_CONFIGURACIONES,
      ]) ? (
        <MenuOption to={DOCUMENTOS_ROUTES.MENU}>Documentos</MenuOption>
      ) : null}
    </Container>
  );
};

export default Menu;
