import { Link as RouterLink } from "react-router-dom";
import MUILink from "@mui/material/Link";

const Link = ({ to, children, onClick, ...muiLinkProps }) => {
  return (
    <MUILink
      component={RouterLink}
      to={to}
      underline="none"
      {...muiLinkProps}
      onClick={onClick}
    >
      {children}
    </MUILink>
  );
};

export default Link;
