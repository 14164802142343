export const SUELDOS_BASE_ROUTE = "/sueldos";

const CONSULTAS_BASE_ROUTE = `${SUELDOS_BASE_ROUTE}/consultas`;
const FILES_BASE_ROUTE = `${SUELDOS_BASE_ROUTE}/files`;

export const SUELDOS_ROUTES = {
  MENU: SUELDOS_BASE_ROUTE,
  CONSULTAS_SUELDOS: `${CONSULTAS_BASE_ROUTE}/sueldos`,
  CONSULTAS_SUELDOS_POR_EMPLEADO: `${CONSULTAS_BASE_ROUTE}/empleado`,
  NEW_FILE: `${FILES_BASE_ROUTE}/files`,
};
