import { useState } from "react";
import useAxios from "axios-hooks";
import styled from "@emotion/styled";
import { useNavigate } from "react-router-dom";
import {
  Paper,
  IconButton,
  OutlinedInput,
  InputLabel,
  InputAdornment,
  FormControl,
  Alert,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  useSnackbarUpdater,
  SNACKBAR_SEVERITY,
} from "../../../providers/SnackbarProvider";
import { COMMONS_ROUTES } from "../constants";
import { useUserState } from "../../../providers/UserProvider";

const StyledPaper = styled(Paper)`
  max-width: 600px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  padding: 25px;
  gap: 30px;
`;

const ModificarContraseña = () => {
  const { username } = useUserState();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [password, setPassword] = useState(null);
  const [oldPassword, setOldPassword] = useState(null);
  const setSnackbar = useSnackbarUpdater();
  const [, putNewUserData] = useAxios(
    {
      url: `/usuarios/password/${username}`,
      method: "PUT",
    },
    { manual: true },
  );
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleClickShowOldPassword = () => {
    setShowOldPassword(!showOldPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setErrorMessage("");
    const formData = Object.fromEntries(new FormData(event.target).entries());
    try {
      await putNewUserData({ data: formData });
      setSnackbar({
        open: true,
        message: "La contraseña se ha modificado con exito!",
        severity: SNACKBAR_SEVERITY.SUCCESS,
      });
      navigate(COMMONS_ROUTES.MENU);
    } catch (error) {
      setErrorMessage(
        error?.response?.data?.message ||
          "Ha ocurrido un error, por favor intentalo mas tarde.",
      );
    }
  };

  return (
    <StyledPaper component="form" onSubmit={handleSubmit} autocomplete="off">
      {errorMessage && (
        <Alert
          sx={{ marginBottom: (theme) => theme.spacing(2) }}
          severity="error"
        >
          {errorMessage}
        </Alert>
      )}
      <FormControl variant="outlined">
        <InputLabel htmlFor="old-password">Actual contraseña</InputLabel>
        <OutlinedInput
          id="old-password"
          label="Actual contraseña"
          type={showOldPassword ? "text" : "password"}
          name="oldPassword"
          value={oldPassword}
          onChange={(event) => setOldPassword(event.target.value)}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowOldPassword}
                onMouseDown={handleMouseDownPassword}
                edge="end"
              >
                {showOldPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          }
        />
      </FormControl>
      <FormControl variant="outlined">
        <InputLabel htmlFor="outlined-password">Nueva contraseña</InputLabel>
        <OutlinedInput
          id="outlined-password"
          label="Nueva contraseña"
          type={showPassword ? "text" : "password"}
          name="password"
          value={password}
          onChange={(event) => setPassword(event.target.value)}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge="end"
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          }
        />
      </FormControl>
      <LoadingButton
        variant="contained"
        type="submit"
        value="edit"
        disabled={!oldPassword || !password}
      >
        MOFIDICAR CONTRASEÑA
      </LoadingButton>
    </StyledPaper>
  );
};

export default ModificarContraseña;
