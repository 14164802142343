export const PLANTA_FUNCIONAL_BASE_ROUTE = "/planta";

export const PLANTA_FUNCIONAL_SHARED_ROUTES = {
  MENU: `${PLANTA_FUNCIONAL_BASE_ROUTE}/menu`,
};

const CONSULTAS_BASE_ROUTE = `${PLANTA_FUNCIONAL_BASE_ROUTE}/secundaria/consultas`;
const CONFIGURACIONES_BASE_ROUTE = `${PLANTA_FUNCIONAL_BASE_ROUTE}/secundaria/configuraciones`;

const CONSULTAS_PRIMARIA_BASE_ROUTE = `${PLANTA_FUNCIONAL_BASE_ROUTE}/primaria/consultas`;
const CONFIGURACIONES_PRIMARIA_BASE_ROUTE = `${PLANTA_FUNCIONAL_BASE_ROUTE}/primaria/configuraciones`;

export const PLANTA_FUNCIONAL_ROUTES = {
  MENU: `${PLANTA_FUNCIONAL_BASE_ROUTE}/menu/secundaria`,
  // consultas
  CONSULTAS_PLANTA: `${CONSULTAS_BASE_ROUTE}/docente`,
  CONSULTAS_MATERIA: `${CONSULTAS_BASE_ROUTE}/materia`,
  CONSULTAS_CARGOS: `${CONSULTAS_BASE_ROUTE}/cargos`,
  CONSULTAS_CURSO: `${CONSULTAS_BASE_ROUTE}/curso`,
  // configuraciones
  CONFIGURACIONES_MATERIAS: `${CONFIGURACIONES_BASE_ROUTE}/materias`,
  CONFIGURACIONES_CARGOS: `${CONFIGURACIONES_BASE_ROUTE}/cargos`,
  CONFIGURACIONES_DOCENTES: `${CONFIGURACIONES_BASE_ROUTE}/docentes`,
};

export const PLANTA_FUNCIONAL_PRIMARIA_ROUTES = {
  MENU: `${PLANTA_FUNCIONAL_BASE_ROUTE}/menu/primaria`,
  // consultas
  CONSULTAS_PLANTA: `${CONSULTAS_PRIMARIA_BASE_ROUTE}/docente`,
  CONSULTAS_MATERIA: `${CONSULTAS_PRIMARIA_BASE_ROUTE}/materia`,
  CONSULTAS_CURSO: `${CONSULTAS_PRIMARIA_BASE_ROUTE}/curso`,
  // configuraciones
  CONFIGURACIONES_MATERIAS: `${CONFIGURACIONES_PRIMARIA_BASE_ROUTE}/materias`,
  CONFIGURACIONES_DOCENTES: `${CONFIGURACIONES_PRIMARIA_BASE_ROUTE}/docentes`,
};
