export const UNIDAD_DANTE_BASE_ROUTE = "/unidad-dante";

const CONSULTAS_BASE_ROUTE = `${UNIDAD_DANTE_BASE_ROUTE}/consultas`;
const CONFIGURACIONES_BASE_ROUTE = `${UNIDAD_DANTE_BASE_ROUTE}/configuraciones`;

export const UNIDAD_DANTE_ROUTES = {
  MENU: UNIDAD_DANTE_BASE_ROUTE,
  // consultas
  CONSULTAS_ITEMS_UD: `${CONSULTAS_BASE_ROUTE}/items`,
  CONSULTAS_HISTORIAL: `${CONSULTAS_BASE_ROUTE}/historial`,
  // configuraciones
  CONFIGURACIONES_RUBROS: `${CONFIGURACIONES_BASE_ROUTE}/rubros`,
  CONFIGURACIONES_VALORES: `${CONFIGURACIONES_BASE_ROUTE}/valores`,
  CONFIGURACIONES_REGISTROS_ITEMS: `${CONFIGURACIONES_BASE_ROUTE}/items`,
  CONFIGURACIONES_MODIFICACION_ITEMS: `${CONFIGURACIONES_BASE_ROUTE}/modificar-items`,
};
