export const BECAS_BASE_ROUTE = "/becas";

const CONSULTAS_BASE_ROUTE = `${BECAS_BASE_ROUTE}/consultas`;
const CONFIGURACIONES_BASE_ROUTE = `${BECAS_BASE_ROUTE}/configuraciones`;

export const BECAS_ROUTES = {
  MENU: BECAS_BASE_ROUTE,
  CONSULTA_BECAS: `${CONSULTAS_BASE_ROUTE}/consulta-becas`,
  CARGAR_BECADO: `${CONFIGURACIONES_BASE_ROUTE}/cargar-becado`,
  MODIFICAR_BECADO: `${CONFIGURACIONES_BASE_ROUTE}/modificar-becado`,
  RENOVAR_BECADO: `${CONFIGURACIONES_BASE_ROUTE}/renovar-becado`,
  CONFIGURACIONES_MOTIVOS: `${CONFIGURACIONES_BASE_ROUTE}/motivos`,
  CONFIGURACIONES_BECAS: `${CONFIGURACIONES_BASE_ROUTE}/becas`,
};
