export const REEMPLAZOS_BASE_ROUTE = "/ausencias";

const CONSULTAS_BASE_ROUTE = `${REEMPLAZOS_BASE_ROUTE}/consultas`;
const FILES_BASE_ROUTE = `${REEMPLAZOS_BASE_ROUTE}/files`;

export const REEMPLAZOS_ROUTES = {
  MENU: REEMPLAZOS_BASE_ROUTE,
  CONSULTAS_POR_DOCENTE: `${CONSULTAS_BASE_ROUTE}/docentes`,
  CONSULTAS_REEMPLAZOS_POR_ESCUELA: `${CONSULTAS_BASE_ROUTE}/escuela`,
  CONSULTAS_CODIGOS: `${CONSULTAS_BASE_ROUTE}/codigos`,
  CONSULTAS_ESTADISTICAS: `${CONSULTAS_BASE_ROUTE}/estadisticas`,
  NEW_FILE: `${FILES_BASE_ROUTE}/files`,
};
